export type Faults =
  | "controllerOverVoltage"
  | "filteredPhaseOverCurrent"
  | "currentSensorCalibration"
  | "currentSensorOverCurrent"
  | "controllerOverTemperature"
  | "motorHallSensorFault"
  | "controllerUnderVoltage"
  | "postStaticGatingTest"
  | "networkCommunicationTimeout"
  | "instantaneousPhaseOverCurrent"
  | "motorOverTemperature"
  | "throttleVoltageOutsideRange"
  | "instantaneousControllerOverVoltage"
  | "internalError"
  | "postDynamicGatingTest"
  | "instantaneousUnderVoltage";

export type Faults2 =
  | "parameterCrc"
  | "currentScaling"
  | "voltageScaling"
  | "headlightUndervoltage"
  | "parameter3Crc"
  | "canBus"
  | "hallStall"
  | "bootloader"
  | "parameter2Crc"
  | "hallVsSensorlessPosition"
  | "dynameTorqueSensorVoltageOutsideRange"
  | "dynameTorqueSensorStaticVoltageFault"
  | "remoteCanFault"
  | "accelerometerSideTiltFault"
  | "openPhaseFault"
  | "analogBrakeFault";

export type Warnings =
  | "communicationTimeout"
  | "hallSensor"
  | "hallStall"
  | "wheelSpeedSensor"
  | "canBus"
  | "hallIllegalSector"
  | "hallIllegalTransition"
  | "batteryLowFoldback"
  | "batteryHighFoldback"
  | "motorTempFoldback"
  | "controllerTempFoldback"
  | "lowSocFoldback"
  | "highSocFoldback"
  | "i2tFoldback"
  | "lowTemperatureControllerFoldback"
  | "linBmsCommunicationTimeout";

export enum ParameterUnit {
  KPH = "kph",
  KM = "km",
  VOLTS = "volts",
  AMPS = "amps",
  AMPS_RMS = "rmsamps",
  WATTS = "watts",
  CELSIUS = "celsius",
  DEGREES = "degrees",
  HENRIES = "henries",
  OHM = "ohm",
  HERTZ = "hertz",
  RPM = "rpm",
  NEWTON_METER = "nm",
  NUMBER = "number",
  PERCENT = "percent",
  ENUM = "enum",
  BIT = "bit",
  MS = "milliseconds",
  US = "microseconds",
  RATIO = "ratio",
  ANGLE = "angle",
  MM = "millimeters",
  PU = "perunit",
  RADIANS = "radians",
  HOURS = "hours",
}

export type Parameter<Name extends string, Address extends number> = {
  address: Address;
  name: Name;
  unit: ParameterUnit;
  readable: boolean;
  writeable: boolean;
  accessLevel: 0 | 1 | 2 | 3 | 4;
};

export type ScaledParameter<
  Name extends string,
  Address extends number
> = Parameter<Name, Address> & {
  type: "scaled";
  scale: number;
};

export type EnumeratedParameter<
  Name extends string,
  Address extends number,
  Values extends string
> = Parameter<Name, Address> & {
  type: "enumerated";
  unit: ParameterUnit.ENUM;
  values: { [key: number]: Values };
};

export type MaskedParameter<
  Name extends string,
  Address extends number,
  Values extends string
> = Parameter<Name, Address> & {
  type: "masked";
  unit: ParameterUnit.BIT;
  mask: {
    [key: number]: Values;
  };
};

export const Param = <
  N extends string,
  A extends number,
  V extends string,
  T extends
    | ScaledParameter<N, A>
    | MaskedParameter<N, A, V>
    | EnumeratedParameter<N, A, V>
>(
  param: T
): T extends ScaledParameter<infer Name, infer Address>
  ? ScaledParameter<Name, Address>
  : T extends MaskedParameter<infer Name, infer Address, infer Values>
  ? MaskedParameter<Name, Address, Values>
  : T extends EnumeratedParameter<infer Name, infer Address, infer Values>
  ? EnumeratedParameter<Name, Address, Values>
  : // @ts-expect-error: forcing some types
    never => param;

export type ParameterValue<T> = T extends MaskedParameter<
  ScaledParameterName | MaskedParameterName | EnumeratedParameterName,
  infer AA,
  infer U
>
  ? { [key in MaskedParameter<MaskedParameterName, AA, U>["mask"][0]]: boolean }
  : T extends EnumeratedParameter<EnumeratedParameterName, infer AA, infer E>
  ? EnumeratedParameter<EnumeratedParameterName, AA, E>["values"][0]
  : number;

export type ParameterNamesOfType<
  Type extends
    | ScaledParameter<string, number>
    | MaskedParameter<string, number, string>
    | EnumeratedParameter<string, number, string>
> = keyof {
  [Key in keyof ParameterMap as ParameterMap[Key] extends Type
    ? Key
    : never]: Key;
};

export type ScaledParameterName = ParameterNamesOfType<
  ScaledParameter<string, number>
>;
export type EnumeratedParameterName = ParameterNamesOfType<
  EnumeratedParameter<string, number, string>
>;
export type MaskedParameterName = ParameterNamesOfType<
  MaskedParameter<string, number, string>
>;

export type WritableParameterNames = keyof {
  [Key in keyof ParameterMap]: ParameterMap[Key]["writeable"] extends true
    ? ParameterMap[Key]
    : never;
};

export const parameters = {
  outputCurrentRating: Param({
    type: "scaled",
    name: "outputCurrentRating",
    address: 0,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  inputVoltageRating: Param({
    type: "scaled",
    name: "inputVoltageRating",
    address: 1,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.VOLTS,
  }),
  switchingFrequency: Param({
    type: "scaled",
    name: "switchingFrequency",
    address: 2,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  deadTime: Param({
    type: "scaled",
    name: "deadTime",
    address: 3,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  baudRate: Param({
    type: "scaled",
    name: "baudRate",
    address: 4,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 0.01,
    unit: ParameterUnit.NUMBER,
  }),
  slaveId: Param({
    type: "scaled",
    name: "slaveId",
    address: 5,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hwConfigurationVector: Param({
    type: "masked",
    name: "hwConfigurationVector",
    address: 6,
    readable: true,
    writeable: true,
    accessLevel: 2,
    unit: ParameterUnit.BIT,
    mask: {
      1: "activeLowOverCurrentEnable",
      2: "comm485Enable",
      4: "currentSensorPowerEnable",
      8: "dpwmModeEnable",
      16: "hwSlaveIdEnable",
      32: "hwParameterSelect",
      64: "lightSensorEnable",
      128: "lowSpeedSensorlessHighFreqInjection",
      256: "lockedParameters",
      512: "invertedCutoffBrake1",
      1024: "sixStepModeEnable",
      2048: "fieldWeakeningSafetyLevel2Disable",
      4096: "spare12",
      8192: "spare13",
      16384: "currentScalingX8",
      32768: "spare15",
    },
  }),
  currentRegulatorKp: Param({
    type: "scaled",
    name: "currentRegulatorKp",
    address: 7,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  currentRegulatorKi: Param({
    type: "scaled",
    name: "currentRegulatorKi",
    address: 8,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.NUMBER,
  }),
  speedRegulatorKp: Param({
    type: "scaled",
    name: "speedRegulatorKp",
    address: 9,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  speedRegulatorKi: Param({
    type: "scaled",
    name: "speedRegulatorKi",
    address: 10,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  speedRegulatorMode: Param({
    type: "enumerated",
    name: "speedRegulatorMode",
    address: 11,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "SpeedMode",
      1: "TorqueMode",
      2: "TorqueModeWithSpeedLimiting",
    },
  }),
  pllKpOld: Param({
    type: "scaled",
    name: "pllKpOld",
    address: 12,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pllKiOld: Param({
    type: "scaled",
    name: "pllKiOld",
    address: 13,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  phaseACurrentGain: Param({
    type: "scaled",
    name: "phaseACurrentGain",
    address: 14,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 64,
    unit: ParameterUnit.NUMBER,
  }),
  voltageGain: Param({
    type: "scaled",
    name: "voltageGain",
    address: 15,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  phaseCCurrentGain: Param({
    type: "scaled",
    name: "phaseCCurrentGain",
    address: 16,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 64,
    unit: ParameterUnit.NUMBER,
  }),
  throttleGain: Param({
    type: "scaled",
    name: "throttleGain",
    address: 17,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  brakeGain: Param({
    type: "scaled",
    name: "brakeGain",
    address: 18,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  bmsGain: Param({
    type: "scaled",
    name: "bmsGain",
    address: 19,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  dcVoltageFilterShift: Param({
    type: "scaled",
    name: "dcVoltageFilterShift",
    address: 20,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  temperatureFilterShift: Param({
    type: "scaled",
    name: "temperatureFilterShift",
    address: 21,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dqAxisFilterShift: Param({
    type: "scaled",
    name: "dqAxisFilterShift",
    address: 22,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  fluxFilterShift: Param({
    type: "scaled",
    name: "fluxFilterShift",
    address: 23,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  fluxHpfShift: Param({
    type: "scaled",
    name: "fluxHpfShift",
    address: 24,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  fluxFrequencyFilterShift: Param({
    type: "scaled",
    name: "fluxFrequencyFilterShift",
    address: 25,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  voltageFeedbackFilterCutoffFrequency: Param({
    type: "scaled",
    name: "voltageFeedbackFilterCutoffFrequency",
    address: 26,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  averagedOverCurrentTripThreshold: Param({
    type: "scaled",
    name: "averagedOverCurrentTripThreshold",
    address: 27,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  averagedOverCurrentTripSampleLength: Param({
    type: "scaled",
    name: "averagedOverCurrentTripSampleLength",
    address: 28,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousOverCurrentTripThreshold: Param({
    type: "scaled",
    name: "instantaneousOverCurrentTripThreshold",
    address: 29,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  phaseCurrentRmsFilterShift: Param({
    type: "scaled",
    name: "phaseCurrentRmsFilterShift",
    address: 30,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  maximumInterruptExecutionTime: Param({
    type: "scaled",
    name: "maximumInterruptExecutionTime",
    address: 31,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  commandTimeoutThreshold: Param({
    type: "scaled",
    name: "commandTimeoutThreshold",
    address: 32,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dcVoltageTripClearHysterisis: Param({
    type: "scaled",
    name: "dcVoltageTripClearHysterisis",
    address: 33,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  heatsinkOverTemperatureTripThreshold: Param({
    type: "scaled",
    name: "heatsinkOverTemperatureTripThreshold",
    address: 34,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  controllerFoldbackStartingTemperature: Param({
    type: "scaled",
    name: "controllerFoldbackStartingTemperature",
    address: 35,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  controllerFoldbackEndingTemperature: Param({
    type: "scaled",
    name: "controllerFoldbackEndingTemperature",
    address: 36,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  controllerTemperatureFeedback0C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback0C",
    address: 37,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  controllerTemperatureFeedback25C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback25C",
    address: 38,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  controllerTemperatureFeedback50C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback50C",
    address: 39,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  controllerTemperatureFeedback75C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback75C",
    address: 40,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  controllerTemperatureFeedback100C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback100C",
    address: 41,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  controllerTemperatureFeedback125C: Param({
    type: "scaled",
    name: "controllerTemperatureFeedback125C",
    address: 42,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  smtDatecode: Param({
    type: "scaled",
    name: "smtDatecode",
    address: 43,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  smtSerialNumber: Param({
    type: "scaled",
    name: "smtSerialNumber",
    address: 44,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  antitheftEnableTime: Param({
    type: "scaled",
    name: "antitheftEnableTime",
    address: 45,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  kdAntiTheft: Param({
    type: "scaled",
    name: "kdAntiTheft",
    address: 46,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  postStaticVoltageTestLimits: Param({
    type: "scaled",
    name: "postStaticVoltageTestLimits",
    address: 47,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicVoltageTestLimits: Param({
    type: "scaled",
    name: "postDynamicVoltageTestLimits",
    address: 48,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  averageCommandTimeoutThreshold: Param({
    type: "scaled",
    name: "averageCommandTimeoutThreshold",
    address: 49,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteCommLossBrakingCurrentLimit: Param({
    type: "scaled",
    name: "remoteCommLossBrakingCurrentLimit",
    address: 50,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  currentRegulatorBandwidth: Param({
    type: "scaled",
    name: "currentRegulatorBandwidth",
    address: 51,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pllBandwidth: Param({
    type: "scaled",
    name: "pllBandwidth",
    address: 52,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pllDamping: Param({
    type: "scaled",
    name: "pllDamping",
    address: 53,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  pllKp: Param({
    type: "scaled",
    name: "pllKp",
    address: 54,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pllKi: Param({
    type: "scaled",
    name: "pllKi",
    address: 55,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 0.03125,
    unit: ParameterUnit.NUMBER,
  }),
  canBaudRate: Param({
    type: "scaled",
    name: "canBaudRate",
    address: 56,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  canId: Param({
    type: "scaled",
    name: "canId",
    address: 57,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  communicationsConfigurationVector: Param({
    type: "masked",
    name: "communicationsConfigurationVector",
    address: 58,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "extended29bitId",
      2: "canFaultsEnable",
      4: "linEnable",
      8: "sleepOnSyncLoss",
      16: "autoOperational",
      32: "tpdoAutosetCobId",
      64: "rpdoAutosetCobId",
      128: "canEnable",
      256: "canMasterEnable",
      512: "canSlaveEnable",
      1024: "ebikeEnable",
      2048: "clubcarEnable",
      4096: "canTerminationDisable",
      8192: "autoCanIdEnable",
      16384: "rtrEnable",
      32768: "virtualIo",
    },
  }),
  batteryResistance: Param({
    type: "scaled",
    name: "batteryResistance",
    address: 59,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  newPhaseACurrentGain: Param({
    type: "scaled",
    name: "newPhaseACurrentGain",
    address: 60,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 8,
    unit: ParameterUnit.NUMBER,
  }),
  newPhaseCCurrentGain: Param({
    type: "scaled",
    name: "newPhaseCCurrentGain",
    address: 61,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 8,
    unit: ParameterUnit.NUMBER,
  }),
  flashParameterReadAccessCode1: Param({
    type: "scaled",
    name: "flashParameterReadAccessCode1",
    address: 62,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallStallFaultTime: Param({
    type: "scaled",
    name: "hallStallFaultTime",
    address: 63,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  port2BaudRate: Param({
    type: "scaled",
    name: "port2BaudRate",
    address: 64,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 0.01,
    unit: ParameterUnit.NUMBER,
  }),
  port2SlaveId: Param({
    type: "scaled",
    name: "port2SlaveId",
    address: 65,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  displayProtocol: Param({
    type: "enumerated",
    name: "displayProtocol",
    address: 66,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Disabled",
      1: "SwlcdOpen9Mode",
      2: "SwlcdClosed",
      3: "Reserved",
      4: "Km5Open9mode",
      5: "Km5Open5mode",
      6: "Wuxing3Mode",
      7: "BafangDisplay",
      8: "Km5s",
      9: "BigStone",
      10: "BafangBms",
      11: "Kt",
      12: "Omni",
      19: "CustomKm5s",
      20: "CustomAptKm5s",
    },
  }),
  parameterUpdateSoftwareVersion: Param({
    type: "scaled",
    name: "parameterUpdateSoftwareVersion",
    address: 67,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1000,
    unit: ParameterUnit.NUMBER,
  }),
  kpAntiTheft: Param({
    type: "scaled",
    name: "kpAntiTheft",
    address: 68,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  lm: Param({
    type: "scaled",
    name: "lm",
    address: 69,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HENRIES,
  }),
  ratedSystemVoltage: Param({
    type: "scaled",
    name: "ratedSystemVoltage",
    address: 70,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.VOLTS,
  }),
  ratedMotorCurrent: Param({
    type: "scaled",
    name: "ratedMotorCurrent",
    address: 71,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  ratedMotorSpeed: Param({
    type: "scaled",
    name: "ratedMotorSpeed",
    address: 72,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  ratedMotorPowerRaceModeThrottlePower: Param({
    type: "scaled",
    name: "ratedMotorPowerRaceModeThrottlePower",
    address: 73,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ls: Param({
    type: "scaled",
    name: "ls",
    address: 74,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HENRIES,
  }),
  rs: Param({
    type: "scaled",
    name: "rs",
    address: 75,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.OHM,
  }),
  displayProtocol2: Param({
    type: "enumerated",
    name: "displayProtocol2",
    address: 76,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Disabled",
      1: "SwlcdOpen9Mode",
      2: "SwlcdClosed",
      3: "Reserved",
      4: "Km5Open9mode",
      5: "Km5Open5mode",
      6: "Wuxing3Mode",
      7: "BafangDisplay",
      8: "Km5s",
      9: "BigStone",
      10: "BafangBms",
      11: "Kt",
      12: "Omni",
      19: "CustomKm5s",
      20: "CustomAptKm5s",
    },
  }),
  motorPositionSensorType: Param({
    type: "enumerated",
    name: "motorPositionSensorType",
    address: 77,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "HallBased",
      1: "HallStartSensorlessRun",
      2: "Sensorless",
      3: "Encoder",
    },
  }),
  polePairs: Param({
    type: "scaled",
    name: "polePairs",
    address: 78,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallOffset: Param({
    type: "scaled",
    name: "hallOffset",
    address: 79,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 91.02222222,
    unit: ParameterUnit.DEGREES,
  }),
  hallSector0: Param({
    type: "scaled",
    name: "hallSector0",
    address: 80,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector1: Param({
    type: "scaled",
    name: "hallSector1",
    address: 81,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector2: Param({
    type: "scaled",
    name: "hallSector2",
    address: 82,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector3: Param({
    type: "scaled",
    name: "hallSector3",
    address: 83,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector4: Param({
    type: "scaled",
    name: "hallSector4",
    address: 84,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector5: Param({
    type: "scaled",
    name: "hallSector5",
    address: 85,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector6: Param({
    type: "scaled",
    name: "hallSector6",
    address: 86,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallSector7: Param({
    type: "scaled",
    name: "hallSector7",
    address: 87,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  hallInterpolationStartFrequency: Param({
    type: "scaled",
    name: "hallInterpolationStartFrequency",
    address: 88,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.HERTZ,
  }),
  hallInterpolationStopFrequency: Param({
    type: "scaled",
    name: "hallInterpolationStopFrequency",
    address: 89,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.HERTZ,
  }),
  motorOverTemperatureTripThreshold: Param({
    type: "scaled",
    name: "motorOverTemperatureTripThreshold",
    address: 90,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  motorFoldbackStartingTemperature: Param({
    type: "scaled",
    name: "motorFoldbackStartingTemperature",
    address: 91,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  motorFoldbackEndingTemperature: Param({
    type: "scaled",
    name: "motorFoldbackEndingTemperature",
    address: 92,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  temperatureFeedback0C: Param({
    type: "scaled",
    name: "temperatureFeedback0C",
    address: 93,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  temperatureFeedback25C: Param({
    type: "scaled",
    name: "temperatureFeedback25C",
    address: 94,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  temperatureFeedback50C: Param({
    type: "scaled",
    name: "temperatureFeedback50C",
    address: 95,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  temperatureFeedback75C: Param({
    type: "scaled",
    name: "temperatureFeedback75C",
    address: 96,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  temperatureFeedback100C: Param({
    type: "scaled",
    name: "temperatureFeedback100C",
    address: 97,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  temperatureFeedback125C: Param({
    type: "scaled",
    name: "temperatureFeedback125C",
    address: 98,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  overloadContinousCurrent: Param({
    type: "scaled",
    name: "overloadContinousCurrent",
    address: 99,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  overloadHeatingCurrent: Param({
    type: "scaled",
    name: "overloadHeatingCurrent",
    address: 100,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  overloadHeatingTime: Param({
    type: "scaled",
    name: "overloadHeatingTime",
    address: 101,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  overloadCoolingCurrent: Param({
    type: "scaled",
    name: "overloadCoolingCurrent",
    address: 102,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  overloadCoolingTime: Param({
    type: "scaled",
    name: "overloadCoolingTime",
    address: 103,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  overloadFoldbackStart: Param({
    type: "scaled",
    name: "overloadFoldbackStart",
    address: 104,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  overloadFoldbackEnd: Param({
    type: "scaled",
    name: "overloadFoldbackEnd",
    address: 105,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  sensorlessOpenLoopStartingCurrent: Param({
    type: "scaled",
    name: "sensorlessOpenLoopStartingCurrent",
    address: 106,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  sensorlessOpenLoopInjectionCurrentRampTime: Param({
    type: "scaled",
    name: "sensorlessOpenLoopInjectionCurrentRampTime",
    address: 107,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  sensorlessClosedLoopEnableFrequency: Param({
    type: "scaled",
    name: "sensorlessClosedLoopEnableFrequency",
    address: 108,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  sensorlessOpenLoopFreqRampTime: Param({
    type: "scaled",
    name: "sensorlessOpenLoopFreqRampTime",
    address: 109,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  sensorlessOpenLoopDcCurrentHoldTime: Param({
    type: "scaled",
    name: "sensorlessOpenLoopDcCurrentHoldTime",
    address: 110,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  batteryUpperRange: Param({
    type: "scaled",
    name: "batteryUpperRange",
    address: 111,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.KM,
  }),
  displayLanguage: Param({
    type: "enumerated",
    name: "displayLanguage",
    address: 112,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "DutchKm",
      1: "GermanKm",
      2: "EnglishKm",
      3: "EnglishKm2",
      4: "EnglishMi",
    },
  }),
  singlePushAssistSource: Param({
    type: "enumerated",
    name: "singlePushAssistSource",
    address: 114,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Brake2",
      1: "Brake1",
      2: "Cruise",
      3: "Pfs",
    },
  }),
  singlePushAssistBoostTimer: Param({
    type: "scaled",
    name: "singlePushAssistBoostTimer",
    address: 115,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  speedModePositiveAccelerationRamp: Param({
    type: "scaled",
    name: "speedModePositiveAccelerationRamp",
    address: 116,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.NUMBER,
  }),
  throttleSensitivityMotorSpeed: Param({
    type: "scaled",
    name: "throttleSensitivityMotorSpeed",
    address: 117,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  speedModeRegenRamp: Param({
    type: "scaled",
    name: "speedModeRegenRamp",
    address: 118,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.NUMBER,
  }),
  startFreeWheelHallTransitions: Param({
    type: "scaled",
    name: "startFreeWheelHallTransitions",
    address: 119,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  batteryAssistLowerRange: Param({
    type: "scaled",
    name: "batteryAssistLowerRange",
    address: 120,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.KM,
  }),
  ratedMotorPowerStreetModePasPower: Param({
    type: "scaled",
    name: "ratedMotorPowerStreetModePasPower",
    address: 121,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ratedMotorPowerRaceModePasPower: Param({
    type: "scaled",
    name: "ratedMotorPowerRaceModePasPower",
    address: 122,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vehicleMaximumSpeedStreetModePas: Param({
    type: "scaled",
    name: "vehicleMaximumSpeedStreetModePas",
    address: 123,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  vehicleMaximumSpeedRaceModePas: Param({
    type: "scaled",
    name: "vehicleMaximumSpeedRaceModePas",
    address: 124,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  customerParameterVersion: Param({
    type: "scaled",
    name: "customerParameterVersion",
    address: 125,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  bluetoothTestParameter: Param({
    type: "scaled",
    name: "bluetoothTestParameter",
    address: 126,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  motorFeatures: Param({
    type: "masked",
    name: "motorFeatures",
    address: 127,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "swapPhaseAC",
      2: "faultTolerantHall",
      4: "bottomBracketMotor",
      8: "batteryI2tEnable",
      16: "singleInputMotorTemperatureAndSpeedPulse",
      32: "reserved5",
      64: "reserved6",
      128: "reserved7",
      256: "reserved8",
      512: "reserved9",
      1024: "reserved10",
      2048: "reserved11",
      4096: "reserved12",
      8192: "reserved13",
      16384: "reserved14",
      32768: "reserved15",
    },
  }),
  hallInterpolationTransitions: Param({
    type: "scaled",
    name: "hallInterpolationTransitions",
    address: 128,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  maximumFieldWeakeningCurrent: Param({
    type: "scaled",
    name: "maximumFieldWeakeningCurrent",
    address: 129,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  flashParameterWriteAccessCode: Param({
    type: "scaled",
    name: "flashParameterWriteAccessCode",
    address: 130,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ratedMotorPowerStreetModeThrottlePower: Param({
    type: "scaled",
    name: "ratedMotorPowerStreetModeThrottlePower",
    address: 131,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecSensorlessOpenLoopStartingCurrent: Param({
    type: "scaled",
    name: "pedalecSensorlessOpenLoopStartingCurrent",
    address: 132,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecSensorlessOpenLoopFreqRampTime: Param({
    type: "scaled",
    name: "pedalecSensorlessOpenLoopFreqRampTime",
    address: 133,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecSensorlessClosedLoopEnableFrequency: Param({
    type: "scaled",
    name: "pedalecSensorlessClosedLoopEnableFrequency",
    address: 134,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  batteryRegenHighSocFoldbackStart: Param({
    type: "scaled",
    name: "batteryRegenHighSocFoldbackStart",
    address: 135,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  batteryRegenHighSocFoldbackEnd: Param({
    type: "scaled",
    name: "batteryRegenHighSocFoldbackEnd",
    address: 136,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  motorTemperatureSource: Param({
    type: "enumerated",
    name: "motorTemperatureSource",
    address: 137,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Brake2",
      1: "Brake1",
      2: "Bms",
      3: "Throttle",
    },
  }),
  walkModeSignalSource: Param({
    type: "enumerated",
    name: "walkModeSignalSource",
    address: 138,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Cruise",
      1: "Pfs",
      2: "Brake1",
      3: "Brake2",
      4: "Serial",
    },
  }),
  alternatePowerSwitchSource: Param({
    type: "enumerated",
    name: "alternatePowerSwitchSource",
    address: 139,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Cruise",
      1: "Pfs",
      2: "Brake1",
      3: "Brake2",
      4: "RemotePower",
      5: "RemoteSpeed",
    },
  }),
  alternateSpeedLimitSwitchSource: Param({
    type: "enumerated",
    name: "alternateSpeedLimitSwitchSource",
    address: 140,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Cruise",
      1: "Pfs",
      2: "Brake1",
      3: "Brake2",
      4: "RemotePower",
      5: "RemoteSpeed",
    },
  }),
  lowBatteryVoltageFoldbackStartVoltage: Param({
    type: "scaled",
    name: "lowBatteryVoltageFoldbackStartVoltage",
    address: 141,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  lowBatteryVoltageFoldbackEndVoltage: Param({
    type: "scaled",
    name: "lowBatteryVoltageFoldbackEndVoltage",
    address: 142,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  coldBatteryFoldbackStartTemperature: Param({
    type: "scaled",
    name: "coldBatteryFoldbackStartTemperature",
    address: 143,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  coldBatteryFoldbackEndTemperature: Param({
    type: "scaled",
    name: "coldBatteryFoldbackEndTemperature",
    address: 144,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  lowBatterySocFoldbackStart: Param({
    type: "scaled",
    name: "lowBatterySocFoldbackStart",
    address: 145,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  lowBatterySocFoldbackEnd: Param({
    type: "scaled",
    name: "lowBatterySocFoldbackEnd",
    address: 146,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousOverVoltageThreshold: Param({
    type: "scaled",
    name: "instantaneousOverVoltageThreshold",
    address: 147,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousUnderVoltageThreshold: Param({
    type: "scaled",
    name: "instantaneousUnderVoltageThreshold",
    address: 148,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  averageOverVoltageThreshold: Param({
    type: "scaled",
    name: "averageOverVoltageThreshold",
    address: 149,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  averageUnderVoltageThreshold: Param({
    type: "scaled",
    name: "averageUnderVoltageThreshold",
    address: 150,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  throttleDeadbandThreshold: Param({
    type: "scaled",
    name: "throttleDeadbandThreshold",
    address: 151,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  throttleFaultRange: Param({
    type: "scaled",
    name: "throttleFaultRange",
    address: 152,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  reserved153: Param({
    type: "scaled",
    name: "reserved153",
    address: 153,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  maximumBrakingTorque: Param({
    type: "scaled",
    name: "maximumBrakingTorque",
    address: 154,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  batteryCurrentLimit: Param({
    type: "scaled",
    name: "batteryCurrentLimit",
    address: 155,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  regenBatteryCurrentLimit: Param({
    type: "scaled",
    name: "regenBatteryCurrentLimit",
    address: 156,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapWattSetpoint1: Param({
    type: "scaled",
    name: "powerMapWattSetpoint1",
    address: 157,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint2: Param({
    type: "scaled",
    name: "powerMapWattSetpoint2",
    address: 158,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint3: Param({
    type: "scaled",
    name: "powerMapWattSetpoint3",
    address: 159,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint4: Param({
    type: "scaled",
    name: "powerMapWattSetpoint4",
    address: 160,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint5: Param({
    type: "scaled",
    name: "powerMapWattSetpoint5",
    address: 161,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint6: Param({
    type: "scaled",
    name: "powerMapWattSetpoint6",
    address: 162,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint7: Param({
    type: "scaled",
    name: "powerMapWattSetpoint7",
    address: 163,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapWattSetpoint8: Param({
    type: "scaled",
    name: "powerMapWattSetpoint8",
    address: 164,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  powerMapSpeedSetpoint1: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint1",
    address: 165,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint2: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint2",
    address: 166,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint3: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint3",
    address: 167,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint4: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint4",
    address: 168,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint5: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint5",
    address: 169,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint6: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint6",
    address: 170,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint7: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint7",
    address: 171,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  powerMapSpeedSetpoint8: Param({
    type: "scaled",
    name: "powerMapSpeedSetpoint8",
    address: 172,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  minimumMotoringTorque: Param({
    type: "scaled",
    name: "minimumMotoringTorque",
    address: 173,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  features2: Param({
    type: "masked",
    name: "features2",
    address: 174,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "headlightAlwaysOnEnable",
      2: "hdqReplicatingHallEnable",
      4: "freeWheel",
      8: "plugBraking",
      16: "throttleFaultTolerantEnable",
      32: "backwardRollingThrottleFaultEnable",
      64: "brake1PullupEnable",
      128: "brake2PullupEnable",
      256: "speedModeThrottleFilter",
      512: "invertBit9OfHwConfig",
      1024: "specialRequestedFeature",
      2048: "throttleMapSpeedInTorqueMode",
      4096: "throttleSensitivity",
      8192: "variableRegenWithSpeed",
      16384: "reservedFeatures2Bit14",
      32768: "enableAccelerometer",
    },
  }),
  pedalSpeedMapOffset: Param({
    type: "scaled",
    name: "pedalSpeedMapOffset",
    address: 175,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  pedalSpeedMapEnd: Param({
    type: "scaled",
    name: "pedalSpeedMapEnd",
    address: 176,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  engineBrakingTorque: Param({
    type: "scaled",
    name: "engineBrakingTorque",
    address: 177,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  pedalecTorqueSymmetry: Param({
    type: "enumerated",
    name: "pedalecTorqueSymmetry",
    address: 178,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "OneCycleDefault",
      1: "HalfCycle",
      2: "QuarterCycle",
      3: "Instantaneous",
    },
  }),
  pedalecPowerGain: Param({
    type: "scaled",
    name: "pedalecPowerGain",
    address: 179,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecInitialTorque: Param({
    type: "scaled",
    name: "pedalecInitialTorque",
    address: 180,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NEWTON_METER,
  }),
  pedalecDeadbandTorque: Param({
    type: "scaled",
    name: "pedalecDeadbandTorque",
    address: 181,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NEWTON_METER,
  }),
  torqueSensorOffset: Param({
    type: "scaled",
    name: "torqueSensorOffset",
    address: 182,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  torqueSensorGain: Param({
    type: "scaled",
    name: "torqueSensorGain",
    address: 183,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogChannel1Select: Param({
    type: "scaled",
    name: "datalogChannel1Select",
    address: 184,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogChannel2Select: Param({
    type: "scaled",
    name: "datalogChannel2Select",
    address: 185,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogChannel3Select: Param({
    type: "scaled",
    name: "datalogChannel3Select",
    address: 186,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogChannel4Select: Param({
    type: "scaled",
    name: "datalogChannel4Select",
    address: 187,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogTriggerLevel: Param({
    type: "scaled",
    name: "datalogTriggerLevel",
    address: 188,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogTriggerMask: Param({
    type: "scaled",
    name: "datalogTriggerMask",
    address: 189,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogHoldOff: Param({
    type: "scaled",
    name: "datalogHoldOff",
    address: 190,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogTimebase: Param({
    type: "scaled",
    name: "datalogTimebase",
    address: 191,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  datalogTriggerMode: Param({
    type: "scaled",
    name: "datalogTriggerMode",
    address: 192,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  wheelSpeedSensorSource: Param({
    type: "enumerated",
    name: "wheelSpeedSensorSource",
    address: 193,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Cruise",
      1: "Brake2",
      2: "HallA",
      3: "DuallHallsSpeedSensor",
      4: "Brake1",
    },
  }),
  highBatteryFoldbackStartingVoltage: Param({
    type: "scaled",
    name: "highBatteryFoldbackStartingVoltage",
    address: 194,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  highBatteryFoldbackEndVoltage: Param({
    type: "scaled",
    name: "highBatteryFoldbackEndVoltage",
    address: 195,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  lowBatterySocAlarmThreshold: Param({
    type: "scaled",
    name: "lowBatterySocAlarmThreshold",
    address: 196,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.PERCENT,
  }),
  voltageModelSocGain: Param({
    type: "scaled",
    name: "voltageModelSocGain",
    address: 197,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.PERCENT,
  }),
  voltageModelSocOffset: Param({
    type: "scaled",
    name: "voltageModelSocOffset",
    address: 198,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.PERCENT,
  }),
  axleTorqueSensor1: Param({
    type: "scaled",
    name: "axleTorqueSensor1",
    address: 199,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  axleTorqueSensor2: Param({
    type: "scaled",
    name: "axleTorqueSensor2",
    address: 200,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  axleTorqueSensor3: Param({
    type: "scaled",
    name: "axleTorqueSensor3",
    address: 201,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  rollingStartSpeed1: Param({
    type: "scaled",
    name: "rollingStartSpeed1",
    address: 202,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  rollingStartSpeed2: Param({
    type: "scaled",
    name: "rollingStartSpeed2",
    address: 203,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  rollingStartSpeed3: Param({
    type: "scaled",
    name: "rollingStartSpeed3",
    address: 204,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  rollingStartSpeed: Param({
    type: "scaled",
    name: "rollingStartSpeed",
    address: 205,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  regenBrakeSpeed: Param({
    type: "scaled",
    name: "regenBrakeSpeed",
    address: 206,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  customerReserved207: Param({
    type: "scaled",
    name: "customerReserved207",
    address: 207,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  controlCommandSource: Param({
    type: "enumerated",
    name: "controlCommandSource",
    address: 208,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Serial",
      1: "Throttle",
      2: "PedalSensor",
      3: "BbTorque",
      4: "ThrottleOrPedal",
      5: "Tmm4TorqueSensor",
    },
  }),
  batteryManagementInterfaceType: Param({
    type: "enumerated",
    name: "batteryManagementInterfaceType",
    address: 209,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "None",
      1: "VoltageModel",
      2: "Analog10V",
      3: "Reserved3",
      4: "Lin",
      5: "Modbus",
    },
  }),
  assistModeSource: Param({
    type: "enumerated",
    name: "assistModeSource",
    address: 210,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "None",
      1: "Analog",
      2: "Display",
      3: "Asi485Display",
      4: "Obsolete",
      5: "NetworkGains",
      6: "Reserved",
      7: "AsiLinPod",
      8: "SinglePushAssistMode",
      9: "Discrete9AssistMode",
    },
  }),
  pedalSensorType: Param({
    type: "enumerated",
    name: "pedalSensorType",
    address: 211,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "SingleHall",
      1: "DualHall",
      2: "FAG",
      3: "QuadratureTorque",
      4: "QuadratureSpeed",
      5: "AxleTorque",
      6: "SingleHallReverse",
      7: "SingleHallTorqueMethode",
      8: "SingleHallTorqueLds",
      9: "DynameTorqueSensor",
    },
  }),
  features: Param({
    type: "masked",
    name: "features",
    address: 212,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "reserved0",
      2: "reverseEnable",
      4: "antiTheftEnable",
      8: "lowSocAlarm",
      16: "regenBrakingEnable",
      32: "motorTempSensorEnable",
      64: "motorStallShutoffEnable",
      128: "reverseInterlockDisable",
      256: "alternateSpeedLimitEnable",
      512: "rollingStartEnable",
      1024: "alternatePowerLimitEnable",
      2048: "walkModeEnable",
      4096: "boostModeEnable",
      8192: "engineBrakingEnable",
      16384: "externalFaultLedOnHdq",
      32768: "disableDisplaysSpeedLimits",
    },
  }),
  throttleFullVoltage: Param({
    type: "scaled",
    name: "throttleFullVoltage",
    address: 213,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  throttleOffVoltage: Param({
    type: "scaled",
    name: "throttleOffVoltage",
    address: 214,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  analogBrakeFullVoltage: Param({
    type: "scaled",
    name: "analogBrakeFullVoltage",
    address: 215,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  analogBrakeOffVoltage: Param({
    type: "scaled",
    name: "analogBrakeOffVoltage",
    address: 216,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  assistHighVoltageReading: Param({
    type: "scaled",
    name: "assistHighVoltageReading",
    address: 217,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  assistLowVoltageReading: Param({
    type: "scaled",
    name: "assistLowVoltageReading",
    address: 218,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  assistGain1: Param({
    type: "scaled",
    name: "assistGain1",
    address: 219,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  positiveMotoringTorqueRamp: Param({
    type: "scaled",
    name: "positiveMotoringTorqueRamp",
    address: 220,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  negativeMotoringTorqueRamp: Param({
    type: "scaled",
    name: "negativeMotoringTorqueRamp",
    address: 221,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecPositiveMotoringTorqueRamp: Param({
    type: "scaled",
    name: "pedalecPositiveMotoringTorqueRamp",
    address: 222,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecNegativeMotoringTorqueRamp: Param({
    type: "scaled",
    name: "pedalecNegativeMotoringTorqueRamp",
    address: 223,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  positiveBrakingTorqueRamp: Param({
    type: "scaled",
    name: "positiveBrakingTorqueRamp",
    address: 224,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  negativeBrakingTorqueRamp: Param({
    type: "scaled",
    name: "negativeBrakingTorqueRamp",
    address: 225,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  gearRatio: Param({
    type: "scaled",
    name: "gearRatio",
    address: 226,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  wheelDiameter: Param({
    type: "scaled",
    name: "wheelDiameter",
    address: 227,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  assistCutOutDistance: Param({
    type: "scaled",
    name: "assistCutOutDistance",
    address: 228,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  vehicleMaximumSpeedRaceModeThrottle: Param({
    type: "scaled",
    name: "vehicleMaximumSpeedRaceModeThrottle",
    address: 229,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  vehicleJogSpeed: Param({
    type: "scaled",
    name: "vehicleJogSpeed",
    address: 230,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  wheelSpeedSensorPulsesPerRevolution: Param({
    type: "scaled",
    name: "wheelSpeedSensorPulsesPerRevolution",
    address: 231,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecMinimumTimeout: Param({
    type: "scaled",
    name: "pedalecMinimumTimeout",
    address: 232,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalecMaximumTimeout: Param({
    type: "scaled",
    name: "pedalecMaximumTimeout",
    address: 233,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalSpeedSensorPulsesPerRevolution: Param({
    type: "scaled",
    name: "pedalSpeedSensorPulsesPerRevolution",
    address: 234,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pedalSenseDelay: Param({
    type: "scaled",
    name: "pedalSenseDelay",
    address: 235,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vehicleMaximumSpeedStreetModeThrottle: Param({
    type: "scaled",
    name: "vehicleMaximumSpeedStreetModeThrottle",
    address: 236,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  mfgFlowCheck: Param({
    type: "scaled",
    name: "mfgFlowCheck",
    address: 237,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgBarcodeType: Param({
    type: "scaled",
    name: "mfgBarcodeType",
    address: 238,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  modelDictionaryNumberLow: Param({
    type: "scaled",
    name: "modelDictionaryNumberLow",
    address: 239,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  modelDictionaryNumberHigh: Param({
    type: "scaled",
    name: "modelDictionaryNumberHigh",
    address: 240,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  assistSpeed1: Param({
    type: "scaled",
    name: "assistSpeed1",
    address: 241,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  torqueSensorVoltageSource: Param({
    type: "scaled",
    name: "torqueSensorVoltageSource",
    address: 242,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  voltageThresholdSingleSpeedTemperatureSource: Param({
    type: "scaled",
    name: "voltageThresholdSingleSpeedTemperatureSource",
    address: 243,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  digitalThrottleSensorSource: Param({
    type: "enumerated",
    name: "digitalThrottleSensorSource",
    address: 244,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Cruise",
      1: "Pfs",
      2: "Throttle",
      3: "Brake1",
      4: "Brake2",
      5: "Bms",
    },
  }),
  assistGain2: Param({
    type: "scaled",
    name: "assistGain2",
    address: 245,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed2: Param({
    type: "scaled",
    name: "assistSpeed2",
    address: 246,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  throttleSensorSource: Param({
    type: "enumerated",
    name: "throttleSensorSource",
    address: 247,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "ThrottleVoltage",
      1: "Brake1",
      2: "Brake2",
      3: "CanTorque",
      4: "Lin",
      5: "NetworkVoltage",
      6: "BmsIn",
    },
  }),
  cutoffBrakeSensorSource: Param({
    type: "enumerated",
    name: "cutoffBrakeSensorSource",
    address: 248,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Brake1",
      1: "Brake2",
      2: "Pfs",
      3: "Cruise",
      4: "Network",
    },
  }),
  regenBrakeSource: Param({
    type: "enumerated",
    name: "regenBrakeSource",
    address: 249,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Brake2",
      1: "Brake1",
      2: "Network",
      3: "Asi",
    },
  }),
  assistGain3: Param({
    type: "scaled",
    name: "assistGain3",
    address: 250,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed3: Param({
    type: "scaled",
    name: "assistSpeed3",
    address: 251,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  vehicleJogSpeed2: Param({
    type: "scaled",
    name: "vehicleJogSpeed2",
    address: 252,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  assistGainWalk: Param({
    type: "scaled",
    name: "assistGainWalk",
    address: 253,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  speedLimitRampTime: Param({
    type: "scaled",
    name: "speedLimitRampTime",
    address: 254,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  canSyncLossTimeout: Param({
    type: "scaled",
    name: "canSyncLossTimeout",
    address: 255,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  softwareVersion: Param({
    type: "scaled",
    name: "softwareVersion",
    address: 256,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1000,
    unit: ParameterUnit.NUMBER,
  }),
  controllerStatus: Param({
    type: "enumerated",
    name: "controllerStatus",
    address: 257,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "PreOperational",
      1: "Idle",
      2: "PostOperational",
      3: "Run",
      4: "Fault",
    },
  }),
  faults: Param({
    type: "masked",
    name: "faults",
    address: 258,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "controllerOverVoltage",
      2: "filteredPhaseOverCurrent",
      4: "currentSensorCalibration",
      8: "currentSensorOverCurrent",
      16: "controllerOverTemperature",
      32: "motorHallSensorFault",
      64: "controllerUnderVoltage",
      128: "postStaticGatingTest",
      256: "networkCommunicationTimeout",
      512: "instantaneousPhaseOverCurrent",
      1024: "motorOverTemperature",
      2048: "throttleVoltageOutsideRange",
      4096: "instantaneousControllerOverVoltage",
      8192: "internalError",
      16384: "postDynamicGatingTest",
      32768: "instantaneousUnderVoltage",
    },
  }),
  powerboardTemperature: Param({
    type: "scaled",
    name: "powerboardTemperature",
    address: 259,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  vehicleSpeed: Param({
    type: "scaled",
    name: "vehicleSpeed",
    address: 260,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  motorTemperature: Param({
    type: "scaled",
    name: "motorTemperature",
    address: 261,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  motorCurrent: Param({
    type: "scaled",
    name: "motorCurrent",
    address: 262,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  motorRpm: Param({
    type: "scaled",
    name: "motorRpm",
    address: 263,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  percentOfRatedRpm: Param({
    type: "scaled",
    name: "percentOfRatedRpm",
    address: 264,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  batteryVoltage: Param({
    type: "scaled",
    name: "batteryVoltage",
    address: 265,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.VOLTS,
  }),
  batteryCurrent: Param({
    type: "scaled",
    name: "batteryCurrent",
    address: 266,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  batteryStateOfCharge: Param({
    type: "scaled",
    name: "batteryStateOfCharge",
    address: 267,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.PERCENT,
  }),
  batteryPower: Param({
    type: "scaled",
    name: "batteryPower",
    address: 268,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  lastFault: Param({
    type: "masked",
    name: "lastFault",
    address: 269,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "controllerOverVoltage",
      2: "filteredPhaseOverCurrent",
      4: "currentSensorCalibration",
      8: "currentSensorOverCurrent",
      16: "controllerOverTemperature",
      32: "motorHallSensorFault",
      64: "controllerUnderVoltage",
      128: "postStaticGatingTest",
      256: "networkCommunicationTimeout",
      512: "instantaneousPhaseOverCurrent",
      1024: "motorOverTemperature",
      2048: "throttleVoltageOutsideRange",
      4096: "instantaneousControllerOverVoltage",
      8192: "internalError",
      16384: "postDynamicGatingTest",
      32768: "instantaneousUnderVoltage",
    },
  }),
  throttleVoltage: Param({
    type: "scaled",
    name: "throttleVoltage",
    address: 270,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  brake1Voltage: Param({
    type: "scaled",
    name: "brake1Voltage",
    address: 271,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  brake2Voltage: Param({
    type: "scaled",
    name: "brake2Voltage",
    address: 272,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  rawPowerboardTemperatureSensorVoltage: Param({
    type: "scaled",
    name: "rawPowerboardTemperatureSensorVoltage",
    address: 273,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  lightSensorVoltage: Param({
    type: "scaled",
    name: "lightSensorVoltage",
    address: 274,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  analogBmsSocVoltage: Param({
    type: "scaled",
    name: "analogBmsSocVoltage",
    address: 275,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.VOLTS,
  }),
  digitalInputs: Param({
    type: "masked",
    name: "digitalInputs",
    address: 276,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "hallC",
      2: "hallB",
      4: "hallA",
      8: "pfs",
      16: "cruise",
      32: "brake1",
      64: "brake2",
      128: "bms",
      256: "throttle",
      512: "remoteBrake",
      1024: "remotePowerRatingSw",
      2048: "remoteRegen1",
      4096: "remoteRegen2",
      8192: "remoteSpeedRatingSw",
      16384: "throttleSpeedRatingSw",
      32768: "reserved15",
    },
  }),
  warnings: Param({
    type: "masked",
    name: "warnings",
    address: 277,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "communicationTimeout",
      2: "hallSensor",
      4: "hallStall",
      8: "wheelSpeedSensor",
      16: "canBus",
      32: "hallIllegalSector",
      64: "hallIllegalTransition",
      128: "batteryLowFoldback",
      256: "batteryHighFoldback",
      512: "motorTempFoldback",
      1024: "controllerTempFoldback",
      2048: "lowSocFoldback",
      4096: "highSocFoldback",
      8192: "i2tFoldback",
      16384: "lowTemperatureControllerFoldback",
      32768: "linBmsCommunicationTimeout",
    },
  }),
  motorElectricalPosition: Param({
    type: "scaled",
    name: "motorElectricalPosition",
    address: 278,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.DEGREES,
  }),
  batteryTemperature: Param({
    type: "scaled",
    name: "batteryTemperature",
    address: 279,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  batteryPowerPercent: Param({
    type: "scaled",
    name: "batteryPowerPercent",
    address: 280,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.PERCENT,
  }),
  dspCoreTemperature: Param({
    type: "scaled",
    name: "dspCoreTemperature",
    address: 281,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.CELSIUS,
  }),
  phaseACurrent: Param({
    type: "scaled",
    name: "phaseACurrent",
    address: 282,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  phaseBCurrent: Param({
    type: "scaled",
    name: "phaseBCurrent",
    address: 283,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  phaseCCurrent: Param({
    type: "scaled",
    name: "phaseCCurrent",
    address: 284,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  phaseAVoltage: Param({
    type: "scaled",
    name: "phaseAVoltage",
    address: 285,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.VOLTS,
  }),
  phaseBVoltage: Param({
    type: "scaled",
    name: "phaseBVoltage",
    address: 286,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.VOLTS,
  }),
  phaseCVoltage: Param({
    type: "scaled",
    name: "phaseCVoltage",
    address: 287,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.VOLTS,
  }),
  bootloaderSoftwareVersion: Param({
    type: "scaled",
    name: "bootloaderSoftwareVersion",
    address: 288,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1000,
    unit: ParameterUnit.NUMBER,
  }),
  twelveVoltSupplyVoltage: Param({
    type: "scaled",
    name: "twelveVoltSupplyVoltage",
    address: 289,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.VOLTS,
  }),
  highSocFoldbackGain: Param({
    type: "scaled",
    name: "highSocFoldbackGain",
    address: 290,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  rawBatteryVoltage: Param({
    type: "scaled",
    name: "rawBatteryVoltage",
    address: 291,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousPhaseAVoltage: Param({
    type: "scaled",
    name: "instantaneousPhaseAVoltage",
    address: 292,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousPhaseBVoltage: Param({
    type: "scaled",
    name: "instantaneousPhaseBVoltage",
    address: 293,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  instantaneousPhaseCVoltage: Param({
    type: "scaled",
    name: "instantaneousPhaseCVoltage",
    address: 294,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  digitalOutputs: Param({
    type: "masked",
    name: "digitalOutputs",
    address: 295,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "headlight",
      2: "rs485Tx",
      4: "hdqTx",
      8: "currentSensorPower",
      16: "mainPower",
      32: "led",
      64: "hardwareOverCurrentReset",
      128: "a",
      256: "b",
      512: "c",
      1024: "runningLight",
      2048: "brakeLight",
      4096: "linEnable",
      8192: "fagEnable",
      16384: "chargerDisable",
      32768: "reserved15",
    },
  }),
  iaPu: Param({
    type: "scaled",
    name: "iaPu",
    address: 296,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  icPu: Param({
    type: "scaled",
    name: "icPu",
    address: 297,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  overloadAccumulator: Param({
    type: "scaled",
    name: "overloadAccumulator",
    address: 298,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  faults2: Param({
    type: "masked",
    name: "faults2",
    address: 299,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "parameterCrc",
      2: "currentScaling",
      4: "voltageScaling",
      8: "headlightUndervoltage",
      16: "parameter3Crc",
      32: "canBus",
      64: "hallStall",
      128: "bootloader",
      256: "parameter2Crc",
      512: "hallVsSensorlessPosition",
      1024: "dynameTorqueSensorVoltageOutsideRange",
      2048: "dynameTorqueSensorStaticVoltageFault",
      4096: "remoteCanFault",
      8192: "accelerometerSideTiltFault",
      16384: "openPhaseFault",
      32768: "analogBrakeFault",
    },
  }),
  postDynamicPhaseUHighVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseUHighVoltage",
    address: 300,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicPhaseULowVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseULowVoltage",
    address: 301,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicPhaseVHighVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseVHighVoltage",
    address: 302,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicPhaseVLowVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseVLowVoltage",
    address: 303,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicPhaseWHighVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseWHighVoltage",
    address: 304,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postDynamicPhaseWLowVoltage: Param({
    type: "scaled",
    name: "postDynamicPhaseWLowVoltage",
    address: 305,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postStaticPhaseUOpenVoltage: Param({
    type: "scaled",
    name: "postStaticPhaseUOpenVoltage",
    address: 306,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postStaticPhaseVOpenVoltage: Param({
    type: "scaled",
    name: "postStaticPhaseVOpenVoltage",
    address: 307,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  postStaticPhaseWOpenVoltage: Param({
    type: "scaled",
    name: "postStaticPhaseWOpenVoltage",
    address: 308,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  phaseRegenCurrentPowerLimit: Param({
    type: "scaled",
    name: "phaseRegenCurrentPowerLimit",
    address: 309,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  highVoltageFoldbackGain: Param({
    type: "scaled",
    name: "highVoltageFoldbackGain",
    address: 310,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  speedSensorWheelRpm: Param({
    type: "scaled",
    name: "speedSensorWheelRpm",
    address: 311,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.RPM,
  }),
  motorWheelRpm: Param({
    type: "scaled",
    name: "motorWheelRpm",
    address: 312,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.RPM,
  }),
  measuredWheelRpm: Param({
    type: "scaled",
    name: "measuredWheelRpm",
    address: 313,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.RPM,
  }),
  localPowerLimitCommand: Param({
    type: "scaled",
    name: "localPowerLimitCommand",
    address: 314,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  phaseACurrentSensorOffset: Param({
    type: "scaled",
    name: "phaseACurrentSensorOffset",
    address: 315,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PERCENT,
  }),
  phaseCCurrentSensorOffset: Param({
    type: "scaled",
    name: "phaseCCurrentSensorOffset",
    address: 316,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PERCENT,
  }),
  lowVoltageFoldbackGain: Param({
    type: "scaled",
    name: "lowVoltageFoldbackGain",
    address: 317,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  phaseMotoringCurrentPowerLimit: Param({
    type: "scaled",
    name: "phaseMotoringCurrentPowerLimit",
    address: 318,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  motorI2tFoldbackGain: Param({
    type: "scaled",
    name: "motorI2tFoldbackGain",
    address: 319,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  motorTemperatureFoldbackGain: Param({
    type: "scaled",
    name: "motorTemperatureFoldbackGain",
    address: 320,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  inverterTemperatureFoldbackGain: Param({
    type: "scaled",
    name: "inverterTemperatureFoldbackGain",
    address: 321,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  speedLimiterOutput: Param({
    type: "scaled",
    name: "speedLimiterOutput",
    address: 322,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  motoringPhaseCurrentLimit: Param({
    type: "scaled",
    name: "motoringPhaseCurrentLimit",
    address: 323,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  lowSocFoldbackGain: Param({
    type: "scaled",
    name: "lowSocFoldbackGain",
    address: 324,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  throttleSetpoint: Param({
    type: "scaled",
    name: "throttleSetpoint",
    address: 325,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  brakeSetpoint: Param({
    type: "scaled",
    name: "brakeSetpoint",
    address: 326,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  ebikeFlags: Param({
    type: "masked",
    name: "ebikeFlags",
    address: 327,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "brake",
      2: "cutOut",
      4: "runReq",
      8: "pedal",
      16: "regen",
      32: "walk",
      64: "walkStart",
      128: "throttle",
      256: "reverseMode",
      512: "interlockOff",
      1024: "pedalRamps",
      2048: "gateReq",
      4096: "gateEnabled",
      8192: "boostMode",
      16384: "antiTheft",
      32768: "freeWheel",
    },
  }),
  instantaneousPedalSpeed: Param({
    type: "scaled",
    name: "instantaneousPedalSpeed",
    address: 328,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  assistSpeedLimit: Param({
    type: "scaled",
    name: "assistSpeedLimit",
    address: 329,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  sensorlessState: Param({
    type: "enumerated",
    name: "sensorlessState",
    address: 330,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "SensorlessIdle",
      1: "SensorlessDcRamp",
      2: "SensorlessDcHold",
      3: "SensorlessFreqRamp",
      4: "SensorlessCloseloop",
      5: "SensorlessStall",
    },
  }),
  averagePedalSpeed: Param({
    type: "scaled",
    name: "averagePedalSpeed",
    address: 331,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  averagePedalTorque: Param({
    type: "scaled",
    name: "averagePedalTorque",
    address: 332,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NEWTON_METER,
  }),
  regenPhaseCurrentLimit: Param({
    type: "scaled",
    name: "regenPhaseCurrentLimit",
    address: 333,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  motorInputPower: Param({
    type: "scaled",
    name: "motorInputPower",
    address: 334,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.WATTS,
  }),
  requestedTorqueCommand: Param({
    type: "scaled",
    name: "requestedTorqueCommand",
    address: 335,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  torqueReference: Param({
    type: "scaled",
    name: "torqueReference",
    address: 336,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  speedLimit: Param({
    type: "scaled",
    name: "speedLimit",
    address: 337,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  pedalSpeedGain: Param({
    type: "scaled",
    name: "pedalSpeedGain",
    address: 338,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistLevel: Param({
    type: "scaled",
    name: "assistLevel",
    address: 339,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  modbus1CrcErrorCount: Param({
    type: "scaled",
    name: "modbus1CrcErrorCount",
    address: 340,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  modbus1HwErrors: Param({
    type: "scaled",
    name: "modbus1HwErrors",
    address: 341,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  modbus2CrcErrorCount: Param({
    type: "scaled",
    name: "modbus2CrcErrorCount",
    address: 342,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  modbus2HwErrors: Param({
    type: "scaled",
    name: "modbus2HwErrors",
    address: 343,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reserved344: Param({
    type: "scaled",
    name: "reserved344",
    address: 344,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  filteredDAxisVoltage: Param({
    type: "scaled",
    name: "filteredDAxisVoltage",
    address: 345,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  filteredQAxisVoltage: Param({
    type: "scaled",
    name: "filteredQAxisVoltage",
    address: 346,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dFeedbackAvg: Param({
    type: "scaled",
    name: "dFeedbackAvg",
    address: 347,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  qFeedbackAvg: Param({
    type: "scaled",
    name: "qFeedbackAvg",
    address: 348,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  axleTorqueSensorOffsetVoltage: Param({
    type: "scaled",
    name: "axleTorqueSensorOffsetVoltage",
    address: 349,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  currentIsrExecutionTime: Param({
    type: "scaled",
    name: "currentIsrExecutionTime",
    address: 350,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  peakIsrExecutionTime: Param({
    type: "scaled",
    name: "peakIsrExecutionTime",
    address: 351,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  peakExecutionTime: Param({
    type: "scaled",
    name: "peakExecutionTime",
    address: 352,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  averageExecutionTime: Param({
    type: "scaled",
    name: "averageExecutionTime",
    address: 353,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  executionTime: Param({
    type: "scaled",
    name: "executionTime",
    address: 354,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  executionTime4: Param({
    type: "scaled",
    name: "executionTime4",
    address: 355,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  executionTime5: Param({
    type: "scaled",
    name: "executionTime5",
    address: 356,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  executionTime6: Param({
    type: "scaled",
    name: "executionTime6",
    address: 357,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  executionTime7: Param({
    type: "scaled",
    name: "executionTime7",
    address: 358,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 60,
    unit: ParameterUnit.US,
  }),
  warnings2: Param({
    type: "masked",
    name: "warnings2",
    address: 359,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "throttleOutOfRange",
      2: "dualSpeedSensorWarning",
      4: "dualSpeedSensorNoPulsesWarning",
      8: "dynamicFlashFull",
      16: "dynamicFlashReadError",
      32: "dynamicFlashWriteError",
      64: "params3Missing",
      128: "reserved7",
      256: "reserved8",
      512: "reserved9",
      1024: "reserved10",
      2048: "reserved11",
      4096: "reserved12",
      8192: "reserved13",
      16384: "reserved14",
      32768: "reserved15",
    },
  }),
  remoteMaximumBatteryCurrentLimit: Param({
    type: "scaled",
    name: "remoteMaximumBatteryCurrentLimit",
    address: 360,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 8,
    unit: ParameterUnit.AMPS,
  }),
  remoteMaximumRegenBatteryCurrentLimit: Param({
    type: "scaled",
    name: "remoteMaximumRegenBatteryCurrentLimit",
    address: 361,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 8,
    unit: ParameterUnit.AMPS,
  }),
  executionTime11: Param({
    type: "scaled",
    name: "executionTime11",
    address: 362,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.US,
  }),
  customCodeCrcHigh: Param({
    type: "scaled",
    name: "customCodeCrcHigh",
    address: 363,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customCodeCrcLow: Param({
    type: "scaled",
    name: "customCodeCrcLow",
    address: 364,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customCodeExecutionTime11: Param({
    type: "scaled",
    name: "customCodeExecutionTime11",
    address: 365,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  speedRegulatorIntegralTerm: Param({
    type: "scaled",
    name: "speedRegulatorIntegralTerm",
    address: 366,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 2048,
    unit: ParameterUnit.PU,
  }),
  speedRegulatorOutput: Param({
    type: "scaled",
    name: "speedRegulatorOutput",
    address: 367,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  speedRegulatorErrorTerm: Param({
    type: "scaled",
    name: "speedRegulatorErrorTerm",
    address: 368,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  speedRegulatorProportionalTerm: Param({
    type: "scaled",
    name: "speedRegulatorProportionalTerm",
    address: 369,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentIntegralTerm: Param({
    type: "scaled",
    name: "dAxisCurrentIntegralTerm",
    address: 370,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentIntegralTerm: Param({
    type: "scaled",
    name: "qAxisCurrentIntegralTerm",
    address: 371,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentOutput: Param({
    type: "scaled",
    name: "dAxisCurrentOutput",
    address: 372,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentOutput: Param({
    type: "scaled",
    name: "qAxisCurrentOutput",
    address: 373,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentError: Param({
    type: "scaled",
    name: "dAxisCurrentError",
    address: 374,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentError: Param({
    type: "scaled",
    name: "qAxisCurrentError",
    address: 375,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentProportionalTerm: Param({
    type: "scaled",
    name: "dAxisCurrentProportionalTerm",
    address: 376,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentProportionalTerm: Param({
    type: "scaled",
    name: "qAxisCurrentProportionalTerm",
    address: 377,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.PU,
  }),
  qAxisLimit: Param({
    type: "scaled",
    name: "qAxisLimit",
    address: 378,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  batteryI2tFoldbackGain: Param({
    type: "scaled",
    name: "batteryI2tFoldbackGain",
    address: 379,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentFeedback: Param({
    type: "scaled",
    name: "dAxisCurrentFeedback",
    address: 380,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentFeedback: Param({
    type: "scaled",
    name: "qAxisCurrentFeedback",
    address: 381,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  dAxisCurrentReference: Param({
    type: "scaled",
    name: "dAxisCurrentReference",
    address: 382,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  qAxisCurrentReference: Param({
    type: "scaled",
    name: "qAxisCurrentReference",
    address: 383,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  maximumMeasurableCurrent: Param({
    type: "scaled",
    name: "maximumMeasurableCurrent",
    address: 384,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  maximumMeasurableVoltage: Param({
    type: "scaled",
    name: "maximumMeasurableVoltage",
    address: 385,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.VOLTS,
  }),
  batteryPositiveFoldbackCurrentLimit: Param({
    type: "scaled",
    name: "batteryPositiveFoldbackCurrentLimit",
    address: 386,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  batteryNegativeFoldbackCurrentLimit: Param({
    type: "scaled",
    name: "batteryNegativeFoldbackCurrentLimit",
    address: 387,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  statorCurrentAlpha: Param({
    type: "scaled",
    name: "statorCurrentAlpha",
    address: 388,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  statorCurrentBeta: Param({
    type: "scaled",
    name: "statorCurrentBeta",
    address: 389,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  fluxAlpha: Param({
    type: "scaled",
    name: "fluxAlpha",
    address: 390,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  fluxBeta: Param({
    type: "scaled",
    name: "fluxBeta",
    address: 391,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  fluxFrequency: Param({
    type: "scaled",
    name: "fluxFrequency",
    address: 392,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  fluxMagnitude: Param({
    type: "scaled",
    name: "fluxMagnitude",
    address: 393,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  fluxAngle: Param({
    type: "scaled",
    name: "fluxAngle",
    address: 394,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  instantaneousOpenLoopAngle: Param({
    type: "scaled",
    name: "instantaneousOpenLoopAngle",
    address: 395,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 91.022222,
    unit: ParameterUnit.DEGREES,
  }),
  calculatedBatteryCurrentMotoringLimit: Param({
    type: "scaled",
    name: "calculatedBatteryCurrentMotoringLimit",
    address: 396,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 8,
    unit: ParameterUnit.AMPS,
  }),
  calculatedBatteryCurrentBrakingLimit: Param({
    type: "scaled",
    name: "calculatedBatteryCurrentBrakingLimit",
    address: 397,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 8,
    unit: ParameterUnit.AMPS,
  }),
  motorTemperatureSensorVoltage: Param({
    type: "scaled",
    name: "motorTemperatureSensorVoltage",
    address: 398,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  puLm: Param({
    type: "scaled",
    name: "puLm",
    address: 399,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  debug1: Param({
    type: "scaled",
    name: "debug1",
    address: 400,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  debug2: Param({
    type: "scaled",
    name: "debug2",
    address: 401,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterWriteAccessCode: Param({
    type: "scaled",
    name: "parameterWriteAccessCode",
    address: 402,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  doNotUse1: Param({
    type: "scaled",
    name: "doNotUse1",
    address: 404,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  doNotUse2: Param({
    type: "scaled",
    name: "doNotUse2",
    address: 405,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS_RMS,
  }),
  doNotUse3: Param({
    type: "scaled",
    name: "doNotUse3",
    address: 406,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  wheelPulseCounter: Param({
    type: "scaled",
    name: "wheelPulseCounter",
    address: 407,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tripMeter: Param({
    type: "scaled",
    name: "tripMeter",
    address: 408,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 100,
    unit: ParameterUnit.KM,
  }),
  pedalecTimeoutDelay: Param({
    type: "scaled",
    name: "pedalecTimeoutDelay",
    address: 409,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pllAngle: Param({
    type: "scaled",
    name: "pllAngle",
    address: 410,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  pllError: Param({
    type: "scaled",
    name: "pllError",
    address: 411,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  pllFrequency: Param({
    type: "scaled",
    name: "pllFrequency",
    address: 412,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  pllProportional: Param({
    type: "scaled",
    name: "pllProportional",
    address: 413,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  pllIntegral: Param({
    type: "scaled",
    name: "pllIntegral",
    address: 414,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.HERTZ,
  }),
  rotorFrequency: Param({
    type: "scaled",
    name: "rotorFrequency",
    address: 415,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  pllReferenceAngle: Param({
    type: "scaled",
    name: "pllReferenceAngle",
    address: 416,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  hallAngle: Param({
    type: "scaled",
    name: "hallAngle",
    address: 417,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 65536,
    unit: ParameterUnit.PU,
  }),
  lastFaults2: Param({
    type: "masked",
    name: "lastFaults2",
    address: 418,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "parameterCrc",
      2: "currentScaling",
      4: "voltageScaling",
      8: "headlightUndervoltage",
      16: "torqueSensor",
      32: "canBus",
      64: "hallStall",
      128: "bootloader",
      256: "parameter2Crc",
      512: "hallVsSensorlessPosition",
      1024: "spare10",
      2048: "spare11",
      4096: "spare12",
      8192: "spare13",
      16384: "spare14",
      32768: "spare15",
    },
  }),
  hallFrequency: Param({
    type: "scaled",
    name: "hallFrequency",
    address: 419,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HERTZ,
  }),
  qLimit: Param({
    type: "scaled",
    name: "qLimit",
    address: 420,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  dLimit: Param({
    type: "scaled",
    name: "dLimit",
    address: 421,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  idRefTrim: Param({
    type: "scaled",
    name: "idRefTrim",
    address: 422,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  idRefFdfwd: Param({
    type: "scaled",
    name: "idRefFdfwd",
    address: 423,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneLm: Param({
    type: "scaled",
    name: "autotuneLm",
    address: 424,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HENRIES,
  }),
  autotuneRs: Param({
    type: "scaled",
    name: "autotuneRs",
    address: 425,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.OHM,
  }),
  autotuneLs: Param({
    type: "scaled",
    name: "autotuneLs",
    address: 426,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HENRIES,
  }),
  puRs: Param({
    type: "scaled",
    name: "puRs",
    address: 427,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  puLs: Param({
    type: "scaled",
    name: "puLs",
    address: 428,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  ratedElectricalFrequency: Param({
    type: "scaled",
    name: "ratedElectricalFrequency",
    address: 429,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 8,
    unit: ParameterUnit.NUMBER,
  }),
  digitalInputs2: Param({
    type: "masked",
    name: "digitalInputs2",
    address: 430,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "hwocPin",
      2: "hwocLatch",
      4: "dig2Reserved2",
      8: "dig2Reserved3",
      16: "dig2Reserved4",
      32: "dig2Reserved5",
      64: "dig2Reserved6",
      128: "dig2Reserved7",
      256: "dig2Reserved8",
      512: "dig2Reserved9",
      1024: "dig2Reserved10",
      2048: "dig2Reserved11",
      4096: "dig2Reserved12",
      8192: "dig2Reserved13",
      16384: "dig2Reserved14",
      32768: "dig2Reserved15",
    },
  }),
  combinedMotorThermalFoldback: Param({
    type: "scaled",
    name: "combinedMotorThermalFoldback",
    address: 431,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  flashParameterCycleCounter: Param({
    type: "scaled",
    name: "flashParameterCycleCounter",
    address: 432,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  iaRms: Param({
    type: "scaled",
    name: "iaRms",
    address: 433,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS_RMS,
  }),
  icRms: Param({
    type: "scaled",
    name: "icRms",
    address: 434,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS_RMS,
  }),
  iqTrimQ12: Param({
    type: "scaled",
    name: "iqTrimQ12",
    address: 435,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  modulationIndex: Param({
    type: "scaled",
    name: "modulationIndex",
    address: 436,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  pwmCmdAPwm: Param({
    type: "scaled",
    name: "pwmCmdAPwm",
    address: 437,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pwmCmdBPwm: Param({
    type: "scaled",
    name: "pwmCmdBPwm",
    address: 438,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  pwmCmdCPwm: Param({
    type: "scaled",
    name: "pwmCmdCPwm",
    address: 439,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector0: Param({
    type: "scaled",
    name: "autotuneHallSector0",
    address: 440,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector1: Param({
    type: "scaled",
    name: "autotuneHallSector1",
    address: 441,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector2: Param({
    type: "scaled",
    name: "autotuneHallSector2",
    address: 442,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector3: Param({
    type: "scaled",
    name: "autotuneHallSector3",
    address: 443,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector4: Param({
    type: "scaled",
    name: "autotuneHallSector4",
    address: 444,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector5: Param({
    type: "scaled",
    name: "autotuneHallSector5",
    address: 445,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector6: Param({
    type: "scaled",
    name: "autotuneHallSector6",
    address: 446,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  autotuneHallSector7: Param({
    type: "scaled",
    name: "autotuneHallSector7",
    address: 447,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  parameterCrc32HighWord: Param({
    type: "scaled",
    name: "parameterCrc32HighWord",
    address: 448,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterCrc32LowWord: Param({
    type: "scaled",
    name: "parameterCrc32LowWord",
    address: 449,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  userAccessLevel: Param({
    type: "scaled",
    name: "userAccessLevel",
    address: 450,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  bootloaderCrc32HighWord: Param({
    type: "scaled",
    name: "bootloaderCrc32HighWord",
    address: 451,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  bootloaderCrc32LowWord: Param({
    type: "scaled",
    name: "bootloaderCrc32LowWord",
    address: 452,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  applicationCrc32HighWord: Param({
    type: "scaled",
    name: "applicationCrc32HighWord",
    address: 453,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  applicationCrc32LowWord: Param({
    type: "scaled",
    name: "applicationCrc32LowWord",
    address: 454,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  siliconVersion: Param({
    type: "scaled",
    name: "siliconVersion",
    address: 455,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  motoringLimitPrefoldback: Param({
    type: "scaled",
    name: "motoringLimitPrefoldback",
    address: 456,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  regenLimitPrefoldback: Param({
    type: "scaled",
    name: "regenLimitPrefoldback",
    address: 457,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PU,
  }),
  peakBackgroundPeriod: Param({
    type: "scaled",
    name: "peakBackgroundPeriod",
    address: 458,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  averageBackgroundPeriod: Param({
    type: "scaled",
    name: "averageBackgroundPeriod",
    address: 459,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 15,
    unit: ParameterUnit.US,
  }),
  autotuneHallOffsetAngle: Param({
    type: "scaled",
    name: "autotuneHallOffsetAngle",
    address: 460,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 91.02222222,
    unit: ParameterUnit.DEGREES,
  }),
  autotuneKv: Param({
    type: "scaled",
    name: "autotuneKv",
    address: 461,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  autotuneRatedRpm: Param({
    type: "scaled",
    name: "autotuneRatedRpm",
    address: 462,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  axleTorqueSensorAssist: Param({
    type: "scaled",
    name: "axleTorqueSensorAssist",
    address: 463,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  rollingStartSpeedAssistMode: Param({
    type: "scaled",
    name: "rollingStartSpeedAssistMode",
    address: 464,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.KPH,
  }),
  otpSerialNumber0: Param({
    type: "scaled",
    name: "otpSerialNumber0",
    address: 465,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber1: Param({
    type: "scaled",
    name: "otpSerialNumber1",
    address: 466,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber2: Param({
    type: "scaled",
    name: "otpSerialNumber2",
    address: 467,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber3: Param({
    type: "scaled",
    name: "otpSerialNumber3",
    address: 468,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber4: Param({
    type: "scaled",
    name: "otpSerialNumber4",
    address: 469,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber5: Param({
    type: "scaled",
    name: "otpSerialNumber5",
    address: 470,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber6: Param({
    type: "scaled",
    name: "otpSerialNumber6",
    address: 471,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  otpSerialNumber7: Param({
    type: "scaled",
    name: "otpSerialNumber7",
    address: 472,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  speedCommand: Param({
    type: "scaled",
    name: "speedCommand",
    address: 473,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  maximumMotoringCurrent: Param({
    type: "scaled",
    name: "maximumMotoringCurrent",
    address: 474,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  maximumBrakingCurrent: Param({
    type: "scaled",
    name: "maximumBrakingCurrent",
    address: 475,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  stateCommand: Param({
    type: "enumerated",
    name: "stateCommand",
    address: 476,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Off",
      1: "Idle",
      2: "Run",
    },
  }),
  appliedTorqueCommand: Param({
    type: "scaled",
    name: "appliedTorqueCommand",
    address: 477,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  runningLightPwm: Param({
    type: "scaled",
    name: "runningLightPwm",
    address: 478,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 2.56,
    unit: ParameterUnit.PERCENT,
  }),
  brakeLightPwm: Param({
    type: "scaled",
    name: "brakeLightPwm",
    address: 479,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 2.56,
    unit: ParameterUnit.PERCENT,
  }),
  testMode: Param({
    type: "enumerated",
    name: "testMode",
    address: 480,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "ClosedLoopCurrentMode",
      1: "PwmMode",
      2: "OpenLoopVoltageMode",
      3: "OpenLoopCurrentMode",
    },
  }),
  motorDiscoverMode: Param({
    type: "enumerated",
    name: "motorDiscoverMode",
    address: 481,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Off",
      1: "MotorStationaryParameterDiscover",
      2: "MovingParameterDiscover",
      3: "Invalid3",
      4: "Invalid4",
      5: "Invalid5",
      6: "CheckMotorPhaseConnections",
      9: "MotorStationaryParameterDiscover2",
    },
  }),
  openLoopModulation: Param({
    type: "scaled",
    name: "openLoopModulation",
    address: 482,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  openLoopCurrent: Param({
    type: "scaled",
    name: "openLoopCurrent",
    address: 483,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.AMPS,
  }),
  openLoopFrequency: Param({
    type: "scaled",
    name: "openLoopFrequency",
    address: 484,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.HERTZ,
  }),
  openLoopAngle: Param({
    type: "scaled",
    name: "openLoopAngle",
    address: 485,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 91.022222,
    unit: ParameterUnit.DEGREES,
  }),
  debugCmd1: Param({
    type: "scaled",
    name: "debugCmd1",
    address: 486,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  debugCmd2: Param({
    type: "scaled",
    name: "debugCmd2",
    address: 487,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ebikeFlags2: Param({
    type: "masked",
    name: "ebikeFlags2",
    address: 488,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "regenOffThrottleActive",
      2: "cruiseEnableActive",
      4: "alternatePowerLimitActive",
      8: "alternateSpeedLimitActive",
      16: "spare4",
      32: "spare5",
      64: "spare6",
      128: "spare7",
      256: "spare8",
      512: "spare9",
      1024: "spare10",
      2048: "spare11",
      4096: "spare12",
      8192: "spare13",
      16384: "spare14",
      32768: "spare15",
    },
  }),
  doNotUse4: Param({
    type: "scaled",
    name: "doNotUse4",
    address: 489,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteSpeedCommand: Param({
    type: "scaled",
    name: "remoteSpeedCommand",
    address: 490,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  remoteMaximumMotoringCurrent: Param({
    type: "scaled",
    name: "remoteMaximumMotoringCurrent",
    address: 491,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  remoteMaximumBrakingCurrent: Param({
    type: "scaled",
    name: "remoteMaximumBrakingCurrent",
    address: 492,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  remoteStateCommand: Param({
    type: "enumerated",
    name: "remoteStateCommand",
    address: 493,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Off",
      1: "Idle",
      2: "Run",
    },
  }),
  remoteTorqueCommand: Param({
    type: "scaled",
    name: "remoteTorqueCommand",
    address: 494,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  remoteThrottleVoltage: Param({
    type: "scaled",
    name: "remoteThrottleVoltage",
    address: 495,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  remoteDigitalCommands: Param({
    type: "masked",
    name: "remoteDigitalCommands",
    address: 496,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "cutOut",
      2: "headLight",
      4: "runLight",
      8: "brkLight",
      16: "chrgDisable",
      32: "altSpeed",
      64: "altPower",
      128: "regen1",
      256: "regen2",
      512: "hdq",
      1024: "disableAnalogRegen",
      2048: "reverseCadenceRegen",
      4096: "enableRemoteBraking",
      8192: "remoteCanFault",
      16384: "disableWheelLock",
      32768: "enableReverse",
    },
  }),
  remoteAnalogBrakeVoltage: Param({
    type: "scaled",
    name: "remoteAnalogBrakeVoltage",
    address: 497,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  parameterReadAccessCode1: Param({
    type: "scaled",
    name: "parameterReadAccessCode1",
    address: 498,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  bidirectionalTorqueCommand: Param({
    type: "scaled",
    name: "bidirectionalTorqueCommand",
    address: 499,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 10000,
    unit: ParameterUnit.NUMBER,
  }),
  displaySpeedLimitCommand: Param({
    type: "scaled",
    name: "displaySpeedLimitCommand",
    address: 500,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  displayAssistLevel: Param({
    type: "scaled",
    name: "displayAssistLevel",
    address: 501,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  displayWalkStatus: Param({
    type: "scaled",
    name: "displayWalkStatus",
    address: 502,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteBatterySoc: Param({
    type: "scaled",
    name: "remoteBatterySoc",
    address: 503,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteBatteryTemperature: Param({
    type: "scaled",
    name: "remoteBatteryTemperature",
    address: 504,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteLightSensorVoltage: Param({
    type: "scaled",
    name: "remoteLightSensorVoltage",
    address: 505,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  remoteAssistMode: Param({
    type: "scaled",
    name: "remoteAssistMode",
    address: 506,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  armDatalogger: Param({
    type: "scaled",
    name: "armDatalogger",
    address: 507,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  faultClear: Param({
    type: "enumerated",
    name: "faultClear",
    address: 508,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      1: "Clear",
    },
  }),
  parameterAccessCode1: Param({
    type: "scaled",
    name: "parameterAccessCode1",
    address: 509,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  loadFirmware: Param({
    type: "scaled",
    name: "loadFirmware",
    address: 510,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  saveParameters: Param({
    type: "enumerated",
    name: "saveParameters",
    address: 511,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      [0x7fff]: "SaveToFlash",
      [0x6fff]: "DynamicSave",
      [0x3fff]: "BurnSerialToOtp",
    },
  }),
  ptable1: Param({
    type: "scaled",
    name: "ptable1",
    address: 1536,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable2: Param({
    type: "scaled",
    name: "ptable2",
    address: 1537,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable3: Param({
    type: "scaled",
    name: "ptable3",
    address: 1538,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable4: Param({
    type: "scaled",
    name: "ptable4",
    address: 1539,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable5: Param({
    type: "scaled",
    name: "ptable5",
    address: 1540,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable6: Param({
    type: "scaled",
    name: "ptable6",
    address: 1541,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable7: Param({
    type: "scaled",
    name: "ptable7",
    address: 1542,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable8: Param({
    type: "scaled",
    name: "ptable8",
    address: 1543,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable9: Param({
    type: "scaled",
    name: "ptable9",
    address: 1544,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  ptable10: Param({
    type: "scaled",
    name: "ptable10",
    address: 1545,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  liveSpeedKp: Param({
    type: "scaled",
    name: "liveSpeedKp",
    address: 1674,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  liveSpeedKi: Param({
    type: "scaled",
    name: "liveSpeedKi",
    address: 1675,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  canAutoId: Param({
    type: "scaled",
    name: "canAutoId",
    address: 1676,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteSpeedRpm: Param({
    type: "scaled",
    name: "remoteSpeedRpm",
    address: 1677,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vehicleMaximumSpeedLive: Param({
    type: "scaled",
    name: "vehicleMaximumSpeedLive",
    address: 1678,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.NUMBER,
  }),
  testBuild: Param({
    type: "scaled",
    name: "testBuild",
    address: 1679,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  remoteMaximumBrakingRuntime: Param({
    type: "scaled",
    name: "remoteMaximumBrakingRuntime",
    address: 1680,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  remoteMotoringFoldback: Param({
    type: "scaled",
    name: "remoteMotoringFoldback",
    address: 1681,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  remoteRegenFoldback: Param({
    type: "scaled",
    name: "remoteRegenFoldback",
    address: 1682,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  motorDiscoverySpeed: Param({
    type: "scaled",
    name: "motorDiscoverySpeed",
    address: 1683,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  vars21684: Param({
    type: "scaled",
    name: "vars21684",
    address: 1684,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21685: Param({
    type: "scaled",
    name: "vars21685",
    address: 1685,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21686: Param({
    type: "scaled",
    name: "vars21686",
    address: 1686,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21687: Param({
    type: "scaled",
    name: "vars21687",
    address: 1687,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21688: Param({
    type: "scaled",
    name: "vars21688",
    address: 1688,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  flashSectorTracker: Param({
    type: "scaled",
    name: "flashSectorTracker",
    address: 1689,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  odometerLow: Param({
    type: "scaled",
    name: "odometerLow",
    address: 1690,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 100,
    unit: ParameterUnit.KM,
  }),
  odometerHigh: Param({
    type: "scaled",
    name: "odometerHigh",
    address: 1691,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 0.00152587890625,
    unit: ParameterUnit.KM,
  }),
  powerOnTimeLow: Param({
    type: "scaled",
    name: "powerOnTimeLow",
    address: 1692,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 120,
    unit: ParameterUnit.HOURS,
  }),
  powerOnTimeHigh: Param({
    type: "scaled",
    name: "powerOnTimeHigh",
    address: 1693,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.HOURS,
  }),
  bootCount: Param({
    type: "scaled",
    name: "bootCount",
    address: 1694,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dynamicFlashPacketCount: Param({
    type: "scaled",
    name: "dynamicFlashPacketCount",
    address: 1695,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  faultsLog: Param({
    type: "masked",
    name: "faultsLog",
    address: 1696,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "controllerOverVoltage",
      2: "filteredPhaseOverCurrent",
      4: "currentSensorCalibration",
      8: "currentSensorOverCurrent",
      16: "controllerOverTemperature",
      32: "motorHallSensorFault",
      64: "controllerUnderVoltage",
      128: "postStaticGatingTest",
      256: "networkCommunicationTimeout",
      512: "instantaneousPhaseOverCurrent",
      1024: "motorOverTemperature",
      2048: "throttleVoltageOutsideRange",
      4096: "instantaneousControllerOverVoltage",
      8192: "internalError",
      16384: "postDynamicGatingTest",
      32768: "instantaneousUnderVoltage",
    },
  }),
  faults2Log: Param({
    type: "masked",
    name: "faults2Log",
    address: 1697,
    readable: true,
    writeable: false,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "parameterCrc",
      2: "currentScaling",
      4: "voltageScaling",
      8: "headlightUndervoltage",
      16: "parameter3Crc",
      32: "canBus",
      64: "hallStall",
      128: "bootloader",
      256: "parameter2Crc",
      512: "hallVsSensorlessPosition",
      1024: "dynameTorqueSensorVoltageOutsideRange",
      2048: "dynameTorqueSensorStaticVoltageFault",
      4096: "remoteCanFault",
      8192: "accelerometerSideTiltFault",
      16384: "openPhaseFault",
      32768: "analogBrakeFault",
    },
  }),
  dynamicFlashSavedVar9: Param({
    type: "scaled",
    name: "dynamicFlashSavedVar9",
    address: 1698,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dynamicFlashSavedVar10: Param({
    type: "scaled",
    name: "dynamicFlashSavedVar10",
    address: 1699,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  dspCoreTemperatureQ4: Param({
    type: "scaled",
    name: "dspCoreTemperatureQ4",
    address: 1700,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.CELSIUS,
  }),
  batteryTemperatureOffsetQ4: Param({
    type: "scaled",
    name: "batteryTemperatureOffsetQ4",
    address: 1701,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.CELSIUS,
  }),
  motorTemperatureHr: Param({
    type: "scaled",
    name: "motorTemperatureHr",
    address: 1702,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.CELSIUS,
  }),
  powerboardTemperatureHr: Param({
    type: "scaled",
    name: "powerboardTemperatureHr",
    address: 1703,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 16,
    unit: ParameterUnit.CELSIUS,
  }),
  remotePowerLimit: Param({
    type: "scaled",
    name: "remotePowerLimit",
    address: 1704,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.PERCENT,
  }),
  sectorHFlashStatus: Param({
    type: "scaled",
    name: "sectorHFlashStatus",
    address: 1705,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  sectorGFlashStatus: Param({
    type: "scaled",
    name: "sectorGFlashStatus",
    address: 1706,
    readable: true,
    writeable: false,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterReadAccessCode2: Param({
    type: "scaled",
    name: "parameterReadAccessCode2",
    address: 1707,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterReadAccessCode3: Param({
    type: "scaled",
    name: "parameterReadAccessCode3",
    address: 1708,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterAccessCode2: Param({
    type: "scaled",
    name: "parameterAccessCode2",
    address: 1709,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  parameterAccessCode3: Param({
    type: "scaled",
    name: "parameterAccessCode3",
    address: 1710,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  i2cAddress: Param({
    type: "scaled",
    name: "i2cAddress",
    address: 1711,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  i2cCommand: Param({
    type: "scaled",
    name: "i2cCommand",
    address: 1712,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  i2cData: Param({
    type: "scaled",
    name: "i2cData",
    address: 1713,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21714: Param({
    type: "scaled",
    name: "vars21714",
    address: 1714,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  vars21715: Param({
    type: "scaled",
    name: "vars21715",
    address: 1715,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl0CrcHigh: Param({
    type: "scaled",
    name: "paramLvl0CrcHigh",
    address: 1784,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl0CrcLow: Param({
    type: "scaled",
    name: "paramLvl0CrcLow",
    address: 1785,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl1CrcHigh: Param({
    type: "scaled",
    name: "paramLvl1CrcHigh",
    address: 1786,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl1CrcLow: Param({
    type: "scaled",
    name: "paramLvl1CrcLow",
    address: 1787,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl2CrcHigh: Param({
    type: "scaled",
    name: "paramLvl2CrcHigh",
    address: 1788,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl2CrcLow: Param({
    type: "scaled",
    name: "paramLvl2CrcLow",
    address: 1789,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl3CrcHigh: Param({
    type: "scaled",
    name: "paramLvl3CrcHigh",
    address: 1790,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  paramLvl3CrcLow: Param({
    type: "scaled",
    name: "paramLvl3CrcLow",
    address: 1791,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo1Map1SubIndex",
    address: 1792,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map1Index: Param({
    type: "scaled",
    name: "rpdo1Map1Index",
    address: 1793,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo1Map2SubIndex",
    address: 1794,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map2Index: Param({
    type: "scaled",
    name: "rpdo1Map2Index",
    address: 1795,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo1Map3SubIndex",
    address: 1796,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map3Index: Param({
    type: "scaled",
    name: "rpdo1Map3Index",
    address: 1797,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo1Map4SubIndex",
    address: 1798,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Map4Index: Param({
    type: "scaled",
    name: "rpdo1Map4Index",
    address: 1799,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Size: Param({
    type: "scaled",
    name: "rpdo1Size",
    address: 1800,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1TransmissionType: Param({
    type: "scaled",
    name: "rpdo1TransmissionType",
    address: 1801,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1Timeout: Param({
    type: "scaled",
    name: "rpdo1Timeout",
    address: 1802,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  spare1803: Param({
    type: "scaled",
    name: "spare1803",
    address: 1803,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo2Map1SubIndex",
    address: 1804,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map1Index: Param({
    type: "scaled",
    name: "rpdo2Map1Index",
    address: 1805,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo2Map2SubIndex",
    address: 1806,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map2Index: Param({
    type: "scaled",
    name: "rpdo2Map2Index",
    address: 1807,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo2Map3SubIndex",
    address: 1808,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map3Index: Param({
    type: "scaled",
    name: "rpdo2Map3Index",
    address: 1809,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo2Map4SubIndex",
    address: 1810,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Map4Index: Param({
    type: "scaled",
    name: "rpdo2Map4Index",
    address: 1811,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Size: Param({
    type: "scaled",
    name: "rpdo2Size",
    address: 1812,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2TransmissionType: Param({
    type: "scaled",
    name: "rpdo2TransmissionType",
    address: 1813,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2Timeout: Param({
    type: "scaled",
    name: "rpdo2Timeout",
    address: 1814,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  spare1815: Param({
    type: "scaled",
    name: "spare1815",
    address: 1815,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo1Map1SubIndex",
    address: 1816,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map1Index: Param({
    type: "scaled",
    name: "tpdo1Map1Index",
    address: 1817,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo1Map2SubIndex",
    address: 1818,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map2Index: Param({
    type: "scaled",
    name: "tpdo1Map2Index",
    address: 1819,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo1Map3SubIndex",
    address: 1820,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map3Index: Param({
    type: "scaled",
    name: "tpdo1Map3Index",
    address: 1821,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo1Map4SubIndex",
    address: 1822,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Map4Index: Param({
    type: "scaled",
    name: "tpdo1Map4Index",
    address: 1823,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1Size: Param({
    type: "scaled",
    name: "tpdo1Size",
    address: 1824,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1TransmissionType: Param({
    type: "scaled",
    name: "tpdo1TransmissionType",
    address: 1825,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1EventTime: Param({
    type: "scaled",
    name: "tpdo1EventTime",
    address: 1826,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1SyncWindow: Param({
    type: "scaled",
    name: "tpdo1SyncWindow",
    address: 1827,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo2Map1SubIndex",
    address: 1828,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map1Index: Param({
    type: "scaled",
    name: "tpdo2Map1Index",
    address: 1829,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo2Map2SubIndex",
    address: 1830,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map2Index: Param({
    type: "scaled",
    name: "tpdo2Map2Index",
    address: 1831,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo2Map3SubIndex",
    address: 1832,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map3Index: Param({
    type: "scaled",
    name: "tpdo2Map3Index",
    address: 1833,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo2Map4SubIndex",
    address: 1834,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Map4Index: Param({
    type: "scaled",
    name: "tpdo2Map4Index",
    address: 1835,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2Size: Param({
    type: "scaled",
    name: "tpdo2Size",
    address: 1836,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2TransmissionType: Param({
    type: "scaled",
    name: "tpdo2TransmissionType",
    address: 1837,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2EventTime: Param({
    type: "scaled",
    name: "tpdo2EventTime",
    address: 1838,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2SyncWindow: Param({
    type: "scaled",
    name: "tpdo2SyncWindow",
    address: 1839,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo3Map1SubIndex",
    address: 1840,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map1Index: Param({
    type: "scaled",
    name: "tpdo3Map1Index",
    address: 1841,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo3Map2SubIndex",
    address: 1842,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map2Index: Param({
    type: "scaled",
    name: "tpdo3Map2Index",
    address: 1843,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo3Map3SubIndex",
    address: 1844,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map3Index: Param({
    type: "scaled",
    name: "tpdo3Map3Index",
    address: 1845,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo3Map4SubIndex",
    address: 1846,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Map4Index: Param({
    type: "scaled",
    name: "tpdo3Map4Index",
    address: 1847,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3Size: Param({
    type: "scaled",
    name: "tpdo3Size",
    address: 1848,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3TransmissionType: Param({
    type: "scaled",
    name: "tpdo3TransmissionType",
    address: 1849,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3EventTime: Param({
    type: "scaled",
    name: "tpdo3EventTime",
    address: 1850,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3SyncWindow: Param({
    type: "scaled",
    name: "tpdo3SyncWindow",
    address: 1851,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo4Map1SubIndex",
    address: 1852,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map1Index: Param({
    type: "scaled",
    name: "tpdo4Map1Index",
    address: 1853,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo4Map2SubIndex",
    address: 1854,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map2Index: Param({
    type: "scaled",
    name: "tpdo4Map2Index",
    address: 1855,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo4Map3SubIndex",
    address: 1856,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map3Index: Param({
    type: "scaled",
    name: "tpdo4Map3Index",
    address: 1857,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo4Map4SubIndex",
    address: 1858,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Map4Index: Param({
    type: "scaled",
    name: "tpdo4Map4Index",
    address: 1859,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4Size: Param({
    type: "scaled",
    name: "tpdo4Size",
    address: 1860,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4TransmissionType: Param({
    type: "scaled",
    name: "tpdo4TransmissionType",
    address: 1861,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4EventTime: Param({
    type: "scaled",
    name: "tpdo4EventTime",
    address: 1862,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4SyncWindow: Param({
    type: "scaled",
    name: "tpdo4SyncWindow",
    address: 1863,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  canPostSleepDelay: Param({
    type: "scaled",
    name: "canPostSleepDelay",
    address: 1864,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  canPostSyncLossDelay: Param({
    type: "scaled",
    name: "canPostSyncLossDelay",
    address: 1865,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  canHeartbeatPeriod: Param({
    type: "scaled",
    name: "canHeartbeatPeriod",
    address: 1866,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  spare1867: Param({
    type: "scaled",
    name: "spare1867",
    address: 1867,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1CobidLow: Param({
    type: "scaled",
    name: "rpdo1CobidLow",
    address: 1868,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo1CobidHigh: Param({
    type: "scaled",
    name: "rpdo1CobidHigh",
    address: 1869,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2CobidLow: Param({
    type: "scaled",
    name: "rpdo2CobidLow",
    address: 1870,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo2CobidHigh: Param({
    type: "scaled",
    name: "rpdo2CobidHigh",
    address: 1871,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1CobidLow: Param({
    type: "scaled",
    name: "tpdo1CobidLow",
    address: 1872,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo1CobidHigh: Param({
    type: "scaled",
    name: "tpdo1CobidHigh",
    address: 1873,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2CobidLow: Param({
    type: "scaled",
    name: "tpdo2CobidLow",
    address: 1874,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo2CobidHigh: Param({
    type: "scaled",
    name: "tpdo2CobidHigh",
    address: 1875,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3CobidLow: Param({
    type: "scaled",
    name: "tpdo3CobidLow",
    address: 1876,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo3CobidHigh: Param({
    type: "scaled",
    name: "tpdo3CobidHigh",
    address: 1877,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4CobidLow: Param({
    type: "scaled",
    name: "tpdo4CobidLow",
    address: 1878,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo4CobidHigh: Param({
    type: "scaled",
    name: "tpdo4CobidHigh",
    address: 1879,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  level1Features: Param({
    type: "masked",
    name: "level1Features",
    address: 1880,
    readable: true,
    writeable: true,
    accessLevel: 1,
    unit: ParameterUnit.BIT,
    mask: {
      1: "disableFieldWeakeningSafetyLimit",
      2: "sensorlessSpeedInHallOperation",
      4: "spare2",
      8: "dynamicFlash",
      16: "spare4",
      32: "spare5",
      64: "spare6",
      128: "spare7",
      256: "spare8",
      512: "spare9",
      1024: "spare10",
      2048: "spare11",
      4096: "spare12",
      8192: "spare13",
      16384: "spare14",
      32768: "spare15",
    },
  }),
  spare1881: Param({
    type: "scaled",
    name: "spare1881",
    address: 1881,
    readable: true,
    writeable: true,
    accessLevel: 1,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  throttleFilterSpeedControl: Param({
    type: "scaled",
    name: "throttleFilterSpeedControl",
    address: 1882,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  throttleFilterHighVoltage: Param({
    type: "scaled",
    name: "throttleFilterHighVoltage",
    address: 1883,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1884: Param({
    type: "scaled",
    name: "reservedParameter1884",
    address: 1884,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1885: Param({
    type: "scaled",
    name: "reservedParameter1885",
    address: 1885,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1886: Param({
    type: "scaled",
    name: "reservedParameter1886",
    address: 1886,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1887: Param({
    type: "scaled",
    name: "reservedParameter1887",
    address: 1887,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1888: Param({
    type: "scaled",
    name: "reservedParameter1888",
    address: 1888,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1889: Param({
    type: "scaled",
    name: "reservedParameter1889",
    address: 1889,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1890: Param({
    type: "scaled",
    name: "reservedParameter1890",
    address: 1890,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1891: Param({
    type: "scaled",
    name: "reservedParameter1891",
    address: 1891,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedParameter1892: Param({
    type: "scaled",
    name: "reservedParameter1892",
    address: 1892,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  accelerometerFirstAngle: Param({
    type: "scaled",
    name: "accelerometerFirstAngle",
    address: 1893,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.DEGREES,
  }),
  accelerometerSecondAngle: Param({
    type: "scaled",
    name: "accelerometerSecondAngle",
    address: 1894,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.DEGREES,
  }),
  accelerometerFirstOffset: Param({
    type: "scaled",
    name: "accelerometerFirstOffset",
    address: 1895,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  accelerometerSecondOffset: Param({
    type: "scaled",
    name: "accelerometerSecondOffset",
    address: 1896,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  accelerometerAngleFilter: Param({
    type: "scaled",
    name: "accelerometerAngleFilter",
    address: 1897,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  accelerometerAngleOffset: Param({
    type: "scaled",
    name: "accelerometerAngleOffset",
    address: 1898,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.DEGREES,
  }),
  requestedStarting1: Param({
    type: "scaled",
    name: "requestedStarting1",
    address: 1899,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  requestedEnding1: Param({
    type: "scaled",
    name: "requestedEnding1",
    address: 1900,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  requestedStarting2: Param({
    type: "scaled",
    name: "requestedStarting2",
    address: 1901,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  requestedEnding2: Param({
    type: "scaled",
    name: "requestedEnding2",
    address: 1902,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  spare1903: Param({
    type: "scaled",
    name: "spare1903",
    address: 1903,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  level2HwBits: Param({
    type: "masked",
    name: "level2HwBits",
    address: 1904,
    readable: true,
    writeable: true,
    accessLevel: 2,
    unit: ParameterUnit.BIT,
    mask: {
      1: "invertHeadlight",
      2: "invertPfs",
      4: "invertCruise",
      8: "invertHdq",
      16: "level2HwBit4Reserved",
      32: "spare5",
      64: "spare6",
      128: "spare7",
      256: "spare8",
      512: "spare9",
      1024: "spare10",
      2048: "spare11",
      4096: "spare12",
      8192: "spare13",
      16384: "spare14",
      32768: "spare15",
    },
  }),
  level2Spare1905: Param({
    type: "scaled",
    name: "level2Spare1905",
    address: 1905,
    readable: true,
    writeable: true,
    accessLevel: 2,
    scale: 40.96,
    unit: ParameterUnit.NUMBER,
  }),
  assistGain4: Param({
    type: "scaled",
    name: "assistGain4",
    address: 1906,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistGain5: Param({
    type: "scaled",
    name: "assistGain5",
    address: 1907,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistGain6: Param({
    type: "scaled",
    name: "assistGain6",
    address: 1908,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistGain7: Param({
    type: "scaled",
    name: "assistGain7",
    address: 1909,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistGain8: Param({
    type: "scaled",
    name: "assistGain8",
    address: 1910,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistGain9: Param({
    type: "scaled",
    name: "assistGain9",
    address: 1911,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed4: Param({
    type: "scaled",
    name: "assistSpeed4",
    address: 1912,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed5: Param({
    type: "scaled",
    name: "assistSpeed5",
    address: 1913,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed6: Param({
    type: "scaled",
    name: "assistSpeed6",
    address: 1914,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed7: Param({
    type: "scaled",
    name: "assistSpeed7",
    address: 1915,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed8: Param({
    type: "scaled",
    name: "assistSpeed8",
    address: 1916,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  assistSpeed9: Param({
    type: "scaled",
    name: "assistSpeed9",
    address: 1917,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  accelerometerSidewaysCutoffAngle: Param({
    type: "scaled",
    name: "accelerometerSidewaysCutoffAngle",
    address: 1918,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  features3: Param({
    type: "masked",
    name: "features3",
    address: 1919,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.BIT,
    mask: {
      1: "throttleBypassAssistLevel",
      2: "enabeJapaneseJisd9115Standard",
      4: "dynameVariablePedalecGain",
      8: "enablePullupPfs",
      16: "enablePullupCruise",
      32: "throttleBypassWithAssistLevel",
      64: "enableRemoteFolbacks",
      128: "ignoreLowThrottleOutsideRangeFault",
      256: "dynamicNegativeSpeedMotoringRamp",
      512: "powerMappingUsesMaxSpeed",
      1024: "cutoffDisableThrottle",
      2048: "redundantThrottle",
      4096: "disableDisplaysWheelsize",
      8192: "spare13",
      16384: "spare14",
      32768: "autoClearFaults",
    },
  }),
  jisd9115VehicleMaximumSpeed: Param({
    type: "scaled",
    name: "jisd9115VehicleMaximumSpeed",
    address: 1920,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  jisd9115FullAssistanceSpeed: Param({
    type: "scaled",
    name: "jisd9115FullAssistanceSpeed",
    address: 1921,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  jisd9115MaximumPedalecAssistanceGain: Param({
    type: "scaled",
    name: "jisd9115MaximumPedalecAssistanceGain",
    address: 1922,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  torqueSensorLowVThreshold: Param({
    type: "scaled",
    name: "torqueSensorLowVThreshold",
    address: 1923,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  torqueSensorHighVThreshold: Param({
    type: "scaled",
    name: "torqueSensorHighVThreshold",
    address: 1924,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  torqueSensorStaticVThreshold: Param({
    type: "scaled",
    name: "torqueSensorStaticVThreshold",
    address: 1925,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  torqueSensorStaticFaultTime: Param({
    type: "scaled",
    name: "torqueSensorStaticFaultTime",
    address: 1926,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  torqueSensorVoltageFilter: Param({
    type: "scaled",
    name: "torqueSensorVoltageFilter",
    address: 1927,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dualSpeedSensorPrimaryPulseSource: Param({
    type: "scaled",
    name: "dualSpeedSensorPrimaryPulseSource",
    address: 1928,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  noDualSpeedPulsesTimeThreshold: Param({
    type: "scaled",
    name: "noDualSpeedPulsesTimeThreshold",
    address: 1929,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  midSpeedSetpointPedalacGain: Param({
    type: "scaled",
    name: "midSpeedSetpointPedalacGain",
    address: 1930,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  endSpeedSetpointPedalacGain: Param({
    type: "scaled",
    name: "endSpeedSetpointPedalacGain",
    address: 1931,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  dynameStartingPedalecGain: Param({
    type: "scaled",
    name: "dynameStartingPedalecGain",
    address: 1932,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  dynameEndingPedalecGain: Param({
    type: "scaled",
    name: "dynameEndingPedalecGain",
    address: 1933,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  dynameRollingStartAvoidTorque: Param({
    type: "scaled",
    name: "dynameRollingStartAvoidTorque",
    address: 1934,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NEWTON_METER,
  }),
  wheelLockDisableSource: Param({
    type: "enumerated",
    name: "wheelLockDisableSource",
    address: 1935,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Pfs",
      1: "Cruise",
      2: "Brake1",
      3: "Remote",
    },
  }),
  reverseEnableSource: Param({
    type: "enumerated",
    name: "reverseEnableSource",
    address: 1936,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "Disabled6019-Cruise6020",
      1: "Pfs6019-Pfs6020",
      2: "Cruise6019-Remote6020",
      3: "Remote6019-Bms-6021",
    },
  }),
  accelerometerInclineSource: Param({
    type: "enumerated",
    name: "accelerometerInclineSource",
    address: 1937,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "XAngle",
      1: "YAngle",
      2: "ZAngle",
    },
  }),
  accelerometerTiltSource: Param({
    type: "enumerated",
    name: "accelerometerTiltSource",
    address: 1938,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      0: "XAngle",
      1: "YAngle",
      2: "ZAngle",
    },
  }),
  accelerometerTiltOffset: Param({
    type: "scaled",
    name: "accelerometerTiltOffset",
    address: 1939,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  jisd9115MinimumPedalecAssistanceGain: Param({
    type: "scaled",
    name: "jisd9115MinimumPedalecAssistanceGain",
    address: 1940,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  dynamicRampPedalSpeedStart: Param({
    type: "scaled",
    name: "dynamicRampPedalSpeedStart",
    address: 1941,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  dynamicRampPedalSpeedEnd: Param({
    type: "scaled",
    name: "dynamicRampPedalSpeedEnd",
    address: 1942,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.RPM,
  }),
  pedalecDynamicNegativeMinRamp: Param({
    type: "scaled",
    name: "pedalecDynamicNegativeMinRamp",
    address: 1943,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  walkSpeedRpm: Param({
    type: "scaled",
    name: "walkSpeedRpm",
    address: 1944,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  endingSlopeForDynamicPedalRamp: Param({
    type: "scaled",
    name: "endingSlopeForDynamicPedalRamp",
    address: 1945,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NUMBER,
  }),
  startingSlopeForDynamicPedalRamp: Param({
    type: "scaled",
    name: "startingSlopeForDynamicPedalRamp",
    address: 1946,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 64,
    unit: ParameterUnit.NUMBER,
  }),
  speedRegulatorKpQ10: Param({
    type: "scaled",
    name: "speedRegulatorKpQ10",
    address: 1947,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1024,
    unit: ParameterUnit.NUMBER,
  }),
  motorTempShortDetectWindow: Param({
    type: "scaled",
    name: "motorTempShortDetectWindow",
    address: 1948,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  walkModeSpeedRegulatorKp: Param({
    type: "scaled",
    name: "walkModeSpeedRegulatorKp",
    address: 1949,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  walkModeSpeedRegulatorKi: Param({
    type: "scaled",
    name: "walkModeSpeedRegulatorKi",
    address: 1950,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 256,
    unit: ParameterUnit.NUMBER,
  }),
  temperatureThresholdCadenceDetection: Param({
    type: "scaled",
    name: "temperatureThresholdCadenceDetection",
    address: 1951,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  analogBrakeDeadbandRange: Param({
    type: "scaled",
    name: "analogBrakeDeadbandRange",
    address: 1952,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  analogBrakeFaultRange: Param({
    type: "scaled",
    name: "analogBrakeFaultRange",
    address: 1953,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.VOLTS,
  }),
  rpmLimit: Param({
    type: "scaled",
    name: "rpmLimit",
    address: 1954,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.RPM,
  }),
  curregKicc: Param({
    type: "scaled",
    name: "curregKicc",
    address: 1955,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4,
    unit: ParameterUnit.NUMBER,
  }),
  encoderSineHighVoltage: Param({
    type: "scaled",
    name: "encoderSineHighVoltage",
    address: 1956,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  encoderSineLowVoltage: Param({
    type: "scaled",
    name: "encoderSineLowVoltage",
    address: 1957,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  encoderCosHighVoltage: Param({
    type: "scaled",
    name: "encoderCosHighVoltage",
    address: 1958,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  encoderCosLowVoltage: Param({
    type: "scaled",
    name: "encoderCosLowVoltage",
    address: 1959,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  encoderSineSource: Param({
    type: "enumerated",
    name: "encoderSineSource",
    address: 1960,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      1: "ThrottleVoltage",
      2: "BmsIn",
      3: "Brake1",
      4: "Brake2",
    },
  }),
  encoderCosSource: Param({
    type: "enumerated",
    name: "encoderCosSource",
    address: 1961,
    readable: true,
    writeable: true,
    accessLevel: 0,
    unit: ParameterUnit.ENUM,
    values: {
      1: "ThrottleVoltage",
      2: "BmsIn",
      3: "Brake1",
      4: "Brake2",
    },
  }),
  encoderOffset: Param({
    type: "scaled",
    name: "encoderOffset",
    address: 1962,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 91.02222222,
    unit: ParameterUnit.DEGREES,
  }),
  speedCalcThreshold: Param({
    type: "scaled",
    name: "speedCalcThreshold",
    address: 1963,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.NUMBER,
  }),
  flashParameterReadAccessCode2: Param({
    type: "scaled",
    name: "flashParameterReadAccessCode2",
    address: 1964,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  flashParameterReadAccessCode3: Param({
    type: "scaled",
    name: "flashParameterReadAccessCode3",
    address: 1965,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  openPhaseFaultThreshold: Param({
    type: "scaled",
    name: "openPhaseFaultThreshold",
    address: 1966,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.AMPS,
  }),
  i2cBusClockRate: Param({
    type: "scaled",
    name: "i2cBusClockRate",
    address: 1967,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  variableLightOutputVoltage: Param({
    type: "scaled",
    name: "variableLightOutputVoltage",
    address: 1968,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 32,
    unit: ParameterUnit.VOLTS,
  }),
  customerParameter11: Param({
    type: "scaled",
    name: "customerParameter11",
    address: 2014,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter12: Param({
    type: "scaled",
    name: "customerParameter12",
    address: 2015,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter1: Param({
    type: "scaled",
    name: "customerParameter1",
    address: 2016,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter2: Param({
    type: "scaled",
    name: "customerParameter2",
    address: 2017,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter3: Param({
    type: "scaled",
    name: "customerParameter3",
    address: 2018,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter4: Param({
    type: "scaled",
    name: "customerParameter4",
    address: 2019,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter5: Param({
    type: "scaled",
    name: "customerParameter5",
    address: 2020,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter6: Param({
    type: "scaled",
    name: "customerParameter6",
    address: 2021,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter7: Param({
    type: "scaled",
    name: "customerParameter7",
    address: 2022,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter8: Param({
    type: "scaled",
    name: "customerParameter8",
    address: 2023,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter9: Param({
    type: "scaled",
    name: "customerParameter9",
    address: 2024,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  customerParameter10: Param({
    type: "scaled",
    name: "customerParameter10",
    address: 2025,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgDateFirstPowerOn: Param({
    type: "scaled",
    name: "mfgDateFirstPowerOn",
    address: 2026,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgFinalTestDate: Param({
    type: "scaled",
    name: "mfgFinalTestDate",
    address: 2027,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  productPartNumber: Param({
    type: "scaled",
    name: "productPartNumber",
    address: 2028,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  productPartNumberRevision: Param({
    type: "scaled",
    name: "productPartNumberRevision",
    address: 2029,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgCustomerParameterFile: Param({
    type: "scaled",
    name: "mfgCustomerParameterFile",
    address: 2030,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgFirmwareFile: Param({
    type: "scaled",
    name: "mfgFirmwareFile",
    address: 2031,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgFlowcheck: Param({
    type: "scaled",
    name: "mfgFlowcheck",
    address: 2032,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgLocationCode: Param({
    type: "scaled",
    name: "mfgLocationCode",
    address: 2033,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgConfigTestStationSerialNumber: Param({
    type: "scaled",
    name: "mfgConfigTestStationSerialNumber",
    address: 2034,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPcbaSerialNumber1: Param({
    type: "scaled",
    name: "mfgPcbaSerialNumber1",
    address: 2035,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPcbaSerialNumber2: Param({
    type: "scaled",
    name: "mfgPcbaSerialNumber2",
    address: 2036,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgAsySerialNumber1: Param({
    type: "scaled",
    name: "mfgAsySerialNumber1",
    address: 2037,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgAsySerialNumber2: Param({
    type: "scaled",
    name: "mfgAsySerialNumber2",
    address: 2038,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPotSerialNumber1: Param({
    type: "scaled",
    name: "mfgPotSerialNumber1",
    address: 2039,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPotSerialNumber2: Param({
    type: "scaled",
    name: "mfgPotSerialNumber2",
    address: 2040,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgAsyBridgeTestStationSerialNumber: Param({
    type: "scaled",
    name: "mfgAsyBridgeTestStationSerialNumber",
    address: 2041,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgAsyFunctionalTestStationSerialNumber: Param({
    type: "scaled",
    name: "mfgAsyFunctionalTestStationSerialNumber",
    address: 2042,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPotBridgeTestStationSerialNumber: Param({
    type: "scaled",
    name: "mfgPotBridgeTestStationSerialNumber",
    address: 2043,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgPotFunctionalTestStationSerialNumber: Param({
    type: "scaled",
    name: "mfgPotFunctionalTestStationSerialNumber",
    address: 2044,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  mfgTestRegister: Param({
    type: "scaled",
    name: "mfgTestRegister",
    address: 2045,
    readable: true,
    writeable: true,
    accessLevel: 3,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  microElectronicsTestRegister: Param({
    type: "scaled",
    name: "microElectronicsTestRegister",
    address: 2046,
    readable: true,
    writeable: true,
    accessLevel: 4,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  teConfiguration: Param({
    type: "scaled",
    name: "teConfiguration",
    address: 2047,
    readable: true,
    writeable: true,
    accessLevel: 4,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo3Map1SubIndex",
    address: 2048,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map1Index: Param({
    type: "scaled",
    name: "rpdo3Map1Index",
    address: 2049,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo3Map2SubIndex",
    address: 2050,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map2Index: Param({
    type: "scaled",
    name: "rpdo3Map2Index",
    address: 2051,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo3Map3SubIndex",
    address: 2052,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map3Index: Param({
    type: "scaled",
    name: "rpdo3Map3Index",
    address: 2053,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo3Map4SubIndex",
    address: 2054,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Map4Index: Param({
    type: "scaled",
    name: "rpdo3Map4Index",
    address: 2055,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Size: Param({
    type: "scaled",
    name: "rpdo3Size",
    address: 2056,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3TransmissionType: Param({
    type: "scaled",
    name: "rpdo3TransmissionType",
    address: 2057,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3Timeout: Param({
    type: "scaled",
    name: "rpdo3Timeout",
    address: 2058,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo3: Param({
    type: "scaled",
    name: "reservedRpdo3",
    address: 2059,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo4Map1SubIndex",
    address: 2060,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map1Index: Param({
    type: "scaled",
    name: "rpdo4Map1Index",
    address: 2061,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo4Map2SubIndex",
    address: 2062,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map2Index: Param({
    type: "scaled",
    name: "rpdo4Map2Index",
    address: 2063,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo4Map3SubIndex",
    address: 2064,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map3Index: Param({
    type: "scaled",
    name: "rpdo4Map3Index",
    address: 2065,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo4Map4SubIndex",
    address: 2066,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Map4Index: Param({
    type: "scaled",
    name: "rpdo4Map4Index",
    address: 2067,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Size: Param({
    type: "scaled",
    name: "rpdo4Size",
    address: 2068,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4TransmissionType: Param({
    type: "scaled",
    name: "rpdo4TransmissionType",
    address: 2069,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4Timeout: Param({
    type: "scaled",
    name: "rpdo4Timeout",
    address: 2070,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo4: Param({
    type: "scaled",
    name: "reservedRpdo4",
    address: 2071,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo5Map1SubIndex",
    address: 2072,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map1Index: Param({
    type: "scaled",
    name: "rpdo5Map1Index",
    address: 2073,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo5Map2SubIndex",
    address: 2074,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map2Index: Param({
    type: "scaled",
    name: "rpdo5Map2Index",
    address: 2075,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo5Map3SubIndex",
    address: 2076,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map3Index: Param({
    type: "scaled",
    name: "rpdo5Map3Index",
    address: 2077,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo5Map4SubIndex",
    address: 2078,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Map4Index: Param({
    type: "scaled",
    name: "rpdo5Map4Index",
    address: 2079,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Size: Param({
    type: "scaled",
    name: "rpdo5Size",
    address: 2080,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5TransmissionType: Param({
    type: "scaled",
    name: "rpdo5TransmissionType",
    address: 2081,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5Timeout: Param({
    type: "scaled",
    name: "rpdo5Timeout",
    address: 2082,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo5: Param({
    type: "scaled",
    name: "reservedRpdo5",
    address: 2083,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo6Map1SubIndex",
    address: 2084,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map1Index: Param({
    type: "scaled",
    name: "rpdo6Map1Index",
    address: 2085,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo6Map2SubIndex",
    address: 2086,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map2Index: Param({
    type: "scaled",
    name: "rpdo6Map2Index",
    address: 2087,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo6Map3SubIndex",
    address: 2088,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map3Index: Param({
    type: "scaled",
    name: "rpdo6Map3Index",
    address: 2089,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo6Map4SubIndex",
    address: 2090,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Map4Index: Param({
    type: "scaled",
    name: "rpdo6Map4Index",
    address: 2091,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Size: Param({
    type: "scaled",
    name: "rpdo6Size",
    address: 2092,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6TransmissionType: Param({
    type: "scaled",
    name: "rpdo6TransmissionType",
    address: 2093,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6Timeout: Param({
    type: "scaled",
    name: "rpdo6Timeout",
    address: 2094,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo6: Param({
    type: "scaled",
    name: "reservedRpdo6",
    address: 2095,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo7Map1SubIndex",
    address: 2096,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map1Index: Param({
    type: "scaled",
    name: "rpdo7Map1Index",
    address: 2097,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo7Map2SubIndex",
    address: 2098,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map2Index: Param({
    type: "scaled",
    name: "rpdo7Map2Index",
    address: 2099,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo7Map3SubIndex",
    address: 2100,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map3Index: Param({
    type: "scaled",
    name: "rpdo7Map3Index",
    address: 2101,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo7Map4SubIndex",
    address: 2102,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Map4Index: Param({
    type: "scaled",
    name: "rpdo7Map4Index",
    address: 2103,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Size: Param({
    type: "scaled",
    name: "rpdo7Size",
    address: 2104,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7TransmissionType: Param({
    type: "scaled",
    name: "rpdo7TransmissionType",
    address: 2105,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7Timeout: Param({
    type: "scaled",
    name: "rpdo7Timeout",
    address: 2106,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo7: Param({
    type: "scaled",
    name: "reservedRpdo7",
    address: 2107,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo8Map1SubIndex",
    address: 2108,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map1Index: Param({
    type: "scaled",
    name: "rpdo8Map1Index",
    address: 2109,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo8Map2SubIndex",
    address: 2110,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map2Index: Param({
    type: "scaled",
    name: "rpdo8Map2Index",
    address: 2111,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo8Map3SubIndex",
    address: 2112,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map3Index: Param({
    type: "scaled",
    name: "rpdo8Map3Index",
    address: 2113,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo8Map4SubIndex",
    address: 2114,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Map4Index: Param({
    type: "scaled",
    name: "rpdo8Map4Index",
    address: 2115,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Size: Param({
    type: "scaled",
    name: "rpdo8Size",
    address: 2116,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8TransmissionType: Param({
    type: "scaled",
    name: "rpdo8TransmissionType",
    address: 2117,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8Timeout: Param({
    type: "scaled",
    name: "rpdo8Timeout",
    address: 2118,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo8: Param({
    type: "scaled",
    name: "reservedRpdo8",
    address: 2119,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo9Map1SubIndex",
    address: 2120,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map1Index: Param({
    type: "scaled",
    name: "rpdo9Map1Index",
    address: 2121,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo9Map2SubIndex",
    address: 2122,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map2Index: Param({
    type: "scaled",
    name: "rpdo9Map2Index",
    address: 2123,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo9Map3SubIndex",
    address: 2124,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map3Index: Param({
    type: "scaled",
    name: "rpdo9Map3Index",
    address: 2125,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo9Map4SubIndex",
    address: 2126,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Map4Index: Param({
    type: "scaled",
    name: "rpdo9Map4Index",
    address: 2127,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Size: Param({
    type: "scaled",
    name: "rpdo9Size",
    address: 2128,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9TransmissionType: Param({
    type: "scaled",
    name: "rpdo9TransmissionType",
    address: 2129,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9Timeout: Param({
    type: "scaled",
    name: "rpdo9Timeout",
    address: 2130,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo9: Param({
    type: "scaled",
    name: "reservedRpdo9",
    address: 2131,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo10Map1SubIndex",
    address: 2132,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map1Index: Param({
    type: "scaled",
    name: "rpdo10Map1Index",
    address: 2133,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo10Map2SubIndex",
    address: 2134,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map2Index: Param({
    type: "scaled",
    name: "rpdo10Map2Index",
    address: 2135,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo10Map3SubIndex",
    address: 2136,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map3Index: Param({
    type: "scaled",
    name: "rpdo10Map3Index",
    address: 2137,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo10Map4SubIndex",
    address: 2138,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Map4Index: Param({
    type: "scaled",
    name: "rpdo10Map4Index",
    address: 2139,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Size: Param({
    type: "scaled",
    name: "rpdo10Size",
    address: 2140,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10TransmissionType: Param({
    type: "scaled",
    name: "rpdo10TransmissionType",
    address: 2141,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10Timeout: Param({
    type: "scaled",
    name: "rpdo10Timeout",
    address: 2142,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo10: Param({
    type: "scaled",
    name: "reservedRpdo10",
    address: 2143,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo11Map1SubIndex",
    address: 2144,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map1Index: Param({
    type: "scaled",
    name: "rpdo11Map1Index",
    address: 2145,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo11Map2SubIndex",
    address: 2146,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map2Index: Param({
    type: "scaled",
    name: "rpdo11Map2Index",
    address: 2147,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo11Map3SubIndex",
    address: 2148,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map3Index: Param({
    type: "scaled",
    name: "rpdo11Map3Index",
    address: 2149,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo11Map4SubIndex",
    address: 2150,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Map4Index: Param({
    type: "scaled",
    name: "rpdo11Map4Index",
    address: 2151,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Size: Param({
    type: "scaled",
    name: "rpdo11Size",
    address: 2152,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11TransmissionType: Param({
    type: "scaled",
    name: "rpdo11TransmissionType",
    address: 2153,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11Timeout: Param({
    type: "scaled",
    name: "rpdo11Timeout",
    address: 2154,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo11: Param({
    type: "scaled",
    name: "reservedRpdo11",
    address: 2155,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map1SubIndex: Param({
    type: "scaled",
    name: "rpdo12Map1SubIndex",
    address: 2156,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map1Index: Param({
    type: "scaled",
    name: "rpdo12Map1Index",
    address: 2157,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map2SubIndex: Param({
    type: "scaled",
    name: "rpdo12Map2SubIndex",
    address: 2158,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map2Index: Param({
    type: "scaled",
    name: "rpdo12Map2Index",
    address: 2159,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map3SubIndex: Param({
    type: "scaled",
    name: "rpdo12Map3SubIndex",
    address: 2160,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map3Index: Param({
    type: "scaled",
    name: "rpdo12Map3Index",
    address: 2161,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map4SubIndex: Param({
    type: "scaled",
    name: "rpdo12Map4SubIndex",
    address: 2162,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Map4Index: Param({
    type: "scaled",
    name: "rpdo12Map4Index",
    address: 2163,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Size: Param({
    type: "scaled",
    name: "rpdo12Size",
    address: 2164,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12TransmissionType: Param({
    type: "scaled",
    name: "rpdo12TransmissionType",
    address: 2165,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12Timeout: Param({
    type: "scaled",
    name: "rpdo12Timeout",
    address: 2166,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  reservedRpdo12: Param({
    type: "scaled",
    name: "reservedRpdo12",
    address: 2167,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo5Map1SubIndex",
    address: 2168,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map1Index: Param({
    type: "scaled",
    name: "tpdo5Map1Index",
    address: 2169,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo5Map2SubIndex",
    address: 2170,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map2Index: Param({
    type: "scaled",
    name: "tpdo5Map2Index",
    address: 2171,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo5Map3SubIndex",
    address: 2172,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map3Index: Param({
    type: "scaled",
    name: "tpdo5Map3Index",
    address: 2173,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo5Map4SubIndex",
    address: 2174,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Map4Index: Param({
    type: "scaled",
    name: "tpdo5Map4Index",
    address: 2175,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5Size: Param({
    type: "scaled",
    name: "tpdo5Size",
    address: 2176,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5TransmissionType: Param({
    type: "scaled",
    name: "tpdo5TransmissionType",
    address: 2177,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5EventTime: Param({
    type: "scaled",
    name: "tpdo5EventTime",
    address: 2178,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5SyncWindow: Param({
    type: "scaled",
    name: "tpdo5SyncWindow",
    address: 2179,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo6Map1SubIndex",
    address: 2180,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map1Index: Param({
    type: "scaled",
    name: "tpdo6Map1Index",
    address: 2181,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo6Map2SubIndex",
    address: 2182,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map2Index: Param({
    type: "scaled",
    name: "tpdo6Map2Index",
    address: 2183,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo6Map3SubIndex",
    address: 2184,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map3Index: Param({
    type: "scaled",
    name: "tpdo6Map3Index",
    address: 2185,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo6Map4SubIndex",
    address: 2186,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Map4Index: Param({
    type: "scaled",
    name: "tpdo6Map4Index",
    address: 2187,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6Size: Param({
    type: "scaled",
    name: "tpdo6Size",
    address: 2188,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6TransmissionType: Param({
    type: "scaled",
    name: "tpdo6TransmissionType",
    address: 2189,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6EventTime: Param({
    type: "scaled",
    name: "tpdo6EventTime",
    address: 2190,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6SyncWindow: Param({
    type: "scaled",
    name: "tpdo6SyncWindow",
    address: 2191,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo7Map1SubIndex",
    address: 2192,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map1Index: Param({
    type: "scaled",
    name: "tpdo7Map1Index",
    address: 2193,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo7Map2SubIndex",
    address: 2194,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map2Index: Param({
    type: "scaled",
    name: "tpdo7Map2Index",
    address: 2195,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo7Map3SubIndex",
    address: 2196,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map3Index: Param({
    type: "scaled",
    name: "tpdo7Map3Index",
    address: 2197,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo7Map4SubIndex",
    address: 2198,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Map4Index: Param({
    type: "scaled",
    name: "tpdo7Map4Index",
    address: 2199,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7Size: Param({
    type: "scaled",
    name: "tpdo7Size",
    address: 2200,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7TransmissionType: Param({
    type: "scaled",
    name: "tpdo7TransmissionType",
    address: 2201,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7EventTime: Param({
    type: "scaled",
    name: "tpdo7EventTime",
    address: 2202,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7SyncWindow: Param({
    type: "scaled",
    name: "tpdo7SyncWindow",
    address: 2203,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo8Map1SubIndex",
    address: 2204,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map1Index: Param({
    type: "scaled",
    name: "tpdo8Map1Index",
    address: 2205,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo8Map2SubIndex",
    address: 2206,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map2Index: Param({
    type: "scaled",
    name: "tpdo8Map2Index",
    address: 2207,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo8Map3SubIndex",
    address: 2208,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map3Index: Param({
    type: "scaled",
    name: "tpdo8Map3Index",
    address: 2209,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo8Map4SubIndex",
    address: 2210,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Map4Index: Param({
    type: "scaled",
    name: "tpdo8Map4Index",
    address: 2211,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8Size: Param({
    type: "scaled",
    name: "tpdo8Size",
    address: 2212,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8TransmissionType: Param({
    type: "scaled",
    name: "tpdo8TransmissionType",
    address: 2213,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8EventTime: Param({
    type: "scaled",
    name: "tpdo8EventTime",
    address: 2214,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8SyncWindow: Param({
    type: "scaled",
    name: "tpdo8SyncWindow",
    address: 2215,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo9Map1SubIndex",
    address: 2216,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map1Index: Param({
    type: "scaled",
    name: "tpdo9Map1Index",
    address: 2217,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo9Map2SubIndex",
    address: 2218,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map2Index: Param({
    type: "scaled",
    name: "tpdo9Map2Index",
    address: 2219,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo9Map3SubIndex",
    address: 2220,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map3Index: Param({
    type: "scaled",
    name: "tpdo9Map3Index",
    address: 2221,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo9Map4SubIndex",
    address: 2222,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Map4Index: Param({
    type: "scaled",
    name: "tpdo9Map4Index",
    address: 2223,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9Size: Param({
    type: "scaled",
    name: "tpdo9Size",
    address: 2224,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9TransmissionType: Param({
    type: "scaled",
    name: "tpdo9TransmissionType",
    address: 2225,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9EventTime: Param({
    type: "scaled",
    name: "tpdo9EventTime",
    address: 2226,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9SyncWindow: Param({
    type: "scaled",
    name: "tpdo9SyncWindow",
    address: 2227,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo10Map1SubIndex",
    address: 2228,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map1Index: Param({
    type: "scaled",
    name: "tpdo10Map1Index",
    address: 2229,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo10Map2SubIndex",
    address: 2230,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map2Index: Param({
    type: "scaled",
    name: "tpdo10Map2Index",
    address: 2231,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo10Map3SubIndex",
    address: 2232,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map3Index: Param({
    type: "scaled",
    name: "tpdo10Map3Index",
    address: 2233,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo10Map4SubIndex",
    address: 2234,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Map4Index: Param({
    type: "scaled",
    name: "tpdo10Map4Index",
    address: 2235,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10Size: Param({
    type: "scaled",
    name: "tpdo10Size",
    address: 2236,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10TransmissionType: Param({
    type: "scaled",
    name: "tpdo10TransmissionType",
    address: 2237,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10EventTime: Param({
    type: "scaled",
    name: "tpdo10EventTime",
    address: 2238,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10SyncWindow: Param({
    type: "scaled",
    name: "tpdo10SyncWindow",
    address: 2239,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo11Map1SubIndex",
    address: 2240,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map1Index: Param({
    type: "scaled",
    name: "tpdo11Map1Index",
    address: 2241,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo11Map2SubIndex",
    address: 2242,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map2Index: Param({
    type: "scaled",
    name: "tpdo11Map2Index",
    address: 2243,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo11Map3SubIndex",
    address: 2244,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map3Index: Param({
    type: "scaled",
    name: "tpdo11Map3Index",
    address: 2245,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo11Map4SubIndex",
    address: 2246,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Map4Index: Param({
    type: "scaled",
    name: "tpdo11Map4Index",
    address: 2247,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11Size: Param({
    type: "scaled",
    name: "tpdo11Size",
    address: 2248,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11TransmissionType: Param({
    type: "scaled",
    name: "tpdo11TransmissionType",
    address: 2249,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11EventTime: Param({
    type: "scaled",
    name: "tpdo11EventTime",
    address: 2250,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11SyncWindow: Param({
    type: "scaled",
    name: "tpdo11SyncWindow",
    address: 2251,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map1SubIndex: Param({
    type: "scaled",
    name: "tpdo12Map1SubIndex",
    address: 2252,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map1Index: Param({
    type: "scaled",
    name: "tpdo12Map1Index",
    address: 2253,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map2SubIndex: Param({
    type: "scaled",
    name: "tpdo12Map2SubIndex",
    address: 2254,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map2Index: Param({
    type: "scaled",
    name: "tpdo12Map2Index",
    address: 2255,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map3SubIndex: Param({
    type: "scaled",
    name: "tpdo12Map3SubIndex",
    address: 2256,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map3Index: Param({
    type: "scaled",
    name: "tpdo12Map3Index",
    address: 2257,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map4SubIndex: Param({
    type: "scaled",
    name: "tpdo12Map4SubIndex",
    address: 2258,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Map4Index: Param({
    type: "scaled",
    name: "tpdo12Map4Index",
    address: 2259,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12Size: Param({
    type: "scaled",
    name: "tpdo12Size",
    address: 2260,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12TransmissionType: Param({
    type: "scaled",
    name: "tpdo12TransmissionType",
    address: 2261,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12EventTime: Param({
    type: "scaled",
    name: "tpdo12EventTime",
    address: 2262,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12SyncWindow: Param({
    type: "scaled",
    name: "tpdo12SyncWindow",
    address: 2263,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3CobidLow: Param({
    type: "scaled",
    name: "rpdo3CobidLow",
    address: 2264,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo3CobidHigh: Param({
    type: "scaled",
    name: "rpdo3CobidHigh",
    address: 2265,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4CobidLow: Param({
    type: "scaled",
    name: "rpdo4CobidLow",
    address: 2266,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo4CobidHigh: Param({
    type: "scaled",
    name: "rpdo4CobidHigh",
    address: 2267,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5CobidLow: Param({
    type: "scaled",
    name: "rpdo5CobidLow",
    address: 2268,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo5CobidHigh: Param({
    type: "scaled",
    name: "rpdo5CobidHigh",
    address: 2269,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6CobidLow: Param({
    type: "scaled",
    name: "rpdo6CobidLow",
    address: 2270,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo6CobidHigh: Param({
    type: "scaled",
    name: "rpdo6CobidHigh",
    address: 2271,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7CobidLow: Param({
    type: "scaled",
    name: "rpdo7CobidLow",
    address: 2272,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo7CobidHigh: Param({
    type: "scaled",
    name: "rpdo7CobidHigh",
    address: 2273,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8CobidLow: Param({
    type: "scaled",
    name: "rpdo8CobidLow",
    address: 2274,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo8CobidHigh: Param({
    type: "scaled",
    name: "rpdo8CobidHigh",
    address: 2275,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9CobidLow: Param({
    type: "scaled",
    name: "rpdo9CobidLow",
    address: 2276,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo9CobidHigh: Param({
    type: "scaled",
    name: "rpdo9CobidHigh",
    address: 2277,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10CobidLow: Param({
    type: "scaled",
    name: "rpdo10CobidLow",
    address: 2278,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo10CobidHigh: Param({
    type: "scaled",
    name: "rpdo10CobidHigh",
    address: 2279,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11CobidLow: Param({
    type: "scaled",
    name: "rpdo11CobidLow",
    address: 2280,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo11CobidHigh: Param({
    type: "scaled",
    name: "rpdo11CobidHigh",
    address: 2281,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12CobidLow: Param({
    type: "scaled",
    name: "rpdo12CobidLow",
    address: 2282,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  rpdo12CobidHigh: Param({
    type: "scaled",
    name: "rpdo12CobidHigh",
    address: 2283,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5CobidLow: Param({
    type: "scaled",
    name: "tpdo5CobidLow",
    address: 2284,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo5CobidHigh: Param({
    type: "scaled",
    name: "tpdo5CobidHigh",
    address: 2285,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6CobidLow: Param({
    type: "scaled",
    name: "tpdo6CobidLow",
    address: 2286,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo6CobidHigh: Param({
    type: "scaled",
    name: "tpdo6CobidHigh",
    address: 2287,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7CobidLow: Param({
    type: "scaled",
    name: "tpdo7CobidLow",
    address: 2288,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo7CobidHigh: Param({
    type: "scaled",
    name: "tpdo7CobidHigh",
    address: 2289,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8CobidLow: Param({
    type: "scaled",
    name: "tpdo8CobidLow",
    address: 2290,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo8CobidHigh: Param({
    type: "scaled",
    name: "tpdo8CobidHigh",
    address: 2291,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9CobidLow: Param({
    type: "scaled",
    name: "tpdo9CobidLow",
    address: 2292,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo9CobidHigh: Param({
    type: "scaled",
    name: "tpdo9CobidHigh",
    address: 2293,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10CobidLow: Param({
    type: "scaled",
    name: "tpdo10CobidLow",
    address: 2294,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo10CobidHigh: Param({
    type: "scaled",
    name: "tpdo10CobidHigh",
    address: 2295,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11CobidLow: Param({
    type: "scaled",
    name: "tpdo11CobidLow",
    address: 2296,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo11CobidHigh: Param({
    type: "scaled",
    name: "tpdo11CobidHigh",
    address: 2297,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12CobidLow: Param({
    type: "scaled",
    name: "tpdo12CobidLow",
    address: 2298,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  tpdo12CobidHigh: Param({
    type: "scaled",
    name: "tpdo12CobidHigh",
    address: 2299,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  dynamicFlashInterval: Param({
    type: "scaled",
    name: "dynamicFlashInterval",
    address: 2300,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 0.1,
    unit: ParameterUnit.NUMBER,
  }),
  spareParams31: Param({
    type: "scaled",
    name: "spareParams31",
    address: 2301,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
  throttleSensitivity: Param({
    type: "scaled",
    name: "throttleSensitivity",
    address: 2302,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 4096,
    unit: ParameterUnit.PU,
  }),
  spareParams32: Param({
    type: "scaled",
    name: "spareParams32",
    address: 2303,
    readable: true,
    writeable: true,
    accessLevel: 0,
    scale: 1,
    unit: ParameterUnit.NUMBER,
  }),
} as const;

export type ParameterMap = typeof parameters;