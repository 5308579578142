import { ChangeEvent, useCallback, useMemo, useState } from "react";
import humanize from "humanize-string";
import Fuse from "fuse.js";
import "chota";

import { parameters } from "./addresses";
const paramList = Object.values(parameters);

export default function App() {
  const [json, setJson] = useState({});
  const [search, setSearch] = useState("");
  const updateSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);
  const matches = useMemo(() => {
    return new Fuse(paramList, {
      keys: [
        "name",
        {
          name: "values",
          getFn: (item) => {
            if (item.type === "enumerated") {
              return Object.values(item.values);
            } else {
              return "";
            }
          }
        },
        {
          name: "mask",
          getFn: (item) => {
            if (item.type === "masked") {
              return Object.values(item.mask);
            } else {
              return "";
            }
          }
        }
      ],
      threshold: 0.3
    }).search(search);
  }, [search]);
  const handleFile = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.item(0);
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      const result = e.target?.result;
      if (!result) return;
      const json = JSON.parse(result.toString());
      if (!json) {
        alert("Invalid file");
        return;
      }
      setJson(json);
    });
    reader.readAsText(file);
  }, []);
  return (
    <div>
      <div className="container">
        <h1 style={{ marginBottom: "5px" }}>ASI BAC Parameter Editor</h1>
      </div>
      <nav
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1000,
          padding: "10px",
          paddingBottom: "3px"
        }}
        className="bg-light"
      >
        <div>
          <div className="row">
            <div className="col-8">
              <input
                className="button primary"
                type="file"
                accept="application/json"
                onChange={handleFile}
              />
            </div>
            <div className="col-4">
              <a
                className="button secondary is-full-width"
                download="new-file.json"
                href={`data:application/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify(json, null, 2)
                )}`}
              >
                Download file
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <input
                type="search"
                placeholder="Search for parameter"
                onChange={updateSearch}
              />
            </div>
          </div>
        </div>
      </nav>
      <table className="striped">
        <tbody>
          {Object.entries<number>(json).map(([address, value]) => {
            const param = paramList.find(
              (p) => p.address === parseInt(address)
            );
            if (!param) return null;
            const match = matches.find(
              (p) => p.item.address === parseInt(address)
            );
            if (search && !match) return null;
            const title = (
              <td align="left">
                {humanize(param.name)}
                <br />
                {param.writeable ? (
                  <div className="tag is-small">
                    👓 Read ✍️ Write
                    {param.accessLevel > 0 ? ` (L${param.accessLevel})` : null}
                  </div>
                ) : (
                  <div className="tag is-small">👓 Read-only</div>
                )}
              </td>
            );
            if (param.type === "scaled") {
              const finalValue = value / param.scale;
              return (
                <tr>
                  {title}
                  <td align="left">
                    <input
                      type="number"
                      style={{ width: "20ch" }}
                      value={finalValue}
                      onChange={(event) => {
                        const val = event.target.valueAsNumber;
                        setJson({
                          ...json,
                          [address]: Math.trunc(val * param.scale)
                        });
                      }}
                    />
                  </td>
                  <td align="left">{param.unit}</td>
                </tr>
              );
            }
            if (param.type === "enumerated") {
              return (
                <tr>
                  {title}
                  <td align="left" colSpan={2}>
                    {Object.entries(param.values).map(([constant, name]) => (
                      <label style={{ display: "block" }}>
                        <input
                          type="radio"
                          name={param.name}
                          value={name}
                          checked={parseInt(constant) === value}
                          onChange={(event) => {
                            const val = event.target.checked;
                            setJson({ ...json, [address]: parseInt(constant) });
                          }}
                        />
                        &nbsp;{humanize(name)}
                      </label>
                    ))}
                  </td>
                </tr>
              );
            }
            if (param.type === "masked") {
              return (
                <tr>
                  {title}
                  <td align="left" colSpan={2}>
                    {Object.entries(param.mask).map(([mask, name]) => (
                      <label style={{ display: "block" }}>
                        <input
                          type="checkbox"
                          name={param.name}
                          value={name}
                          checked={(parseInt(mask) & value) > 0}
                          onChange={(event) => {
                            const val = event.target.checked;
                            const bit = parseInt(mask);
                            setJson({ ...json, [address]: value ^ bit });
                          }}
                        />
                        &nbsp;{humanize(name)}
                      </label>
                    ))}
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>
  );
}
